<template>
  <div class="slide-container full-height bg-blue">
    <div class="slide-content">
      <ion-row class="row-padding" style="height: 65%">
        <ion-col class="ion-text-left" size="12">
          <span class="stat-heading secondary">2,286,630</span>
          <p>
            <span class="secondary sub-stat">{{ user.name }}</span
            >, thank you for using The Clubhouse to track your golf journey
            throughout 2021.
          </p>
          <p>
            Much like 2020, the last year has been a difficult one in terms of
            getting on the course. However, Clubhouse members did a great job of
            getting out at any opportunity with members and their friends
            playing over
            <span class="secondary sub-stat">2,286,630</span> rounds in 2021.
          </p>
          <p>
            The average handicap of a Clubhouse member is
            <span class="secondary sub-stat">{{ (15.718).toFixed(1) }}</span>
            with <span class="secondary sub-stat">WA</span> having the best
            average at
            <span class="secondary sub-stat">{{ (14.97).toFixed(1) }}</span
            >.
          </p>
        </ion-col>
      </ion-row>
      <ion-row class="row-padding" style="height: 35%">
        <ion-col class="ion-text-left" size="12">
          <p style="color: #fff">
            Discover your golf stats from the last year with The Clubhouse
          </p>
          <span class="button-start" @click="start"
            >Get Started <ion-icon :icon="arrowForwardOutline"></ion-icon
          ></span>
        </ion-col>
      </ion-row>
    </div>
  </div>
</template>

<script>
import { IonRow, IonCol, IonIcon } from '@ionic/vue'
import { arrowForwardOutline } from 'ionicons/icons'
import stats from '@/modules/stats'
export default {
  components: {
    IonRow,
    IonCol,
    IonIcon,
  },
  props: {
    slider: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const { user } = stats()
    // 2229980
    const start = () => {
      props.slider.slideNext()
      props.slider.autoplay.start()
    }

    return {
      start,
      user,
      arrowForwardOutline,
    }
  },
}
</script>

<style lang="scss">
.button-start {
  width: 100%;
  display: block;
  height: 40px;
  background: var(--color-yellow);
  border-radius: 5px;
  color: var(--color-blue);
  padding: 0 10px;
  line-height: 40px;
  font-size: 1.15rem;
  font-weight: 600;

  ion-icon {
    display: inline-block;
    vertical-align: middle;
    line-height: 40px;
    font-size: 1.25rem;
    margin-bottom: 5px;
  }
}
.slide-container {
  position: relative;

  &.bg-blue {
    background: var(--color-blue);
    color: #fff;

    .secondary {
      color: var(--color-yellow);
    }
  }

  &.bg-pale {
  }

  .slide-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 800px;
    height: 100%;
    width: 100%;
    max-width: 400px;

    .row-padding {
      ion-col {
        padding: 1rem;
      }
    }

    @media (max-width: 400px) {
      width: 100%;
      height: 100%;
    }

    .stat-heading {
      font-weight: 800;
      font-size: 4rem;
      display: block;
    }

    .stat-heading {
      font-weight: 800;
      font-size: 4rem;
      display: block;
    }

    .stat-sub-heading {
      font-weight: 800;
      font-size: 2rem;
      display: block;
    }

    p {
      font-size: 1rem;
    }

    .sub-stat {
      font-weight: 600;
      font-size: 1rem;
    }
  }
}
</style>
