<template>
  <div
    class="slide-container full-height bg-blue share-slider-2"
    ref="share-slider-2"
  >
    <div class="slide-content">
      <ion-row class="row-padding" style="height: 25%">
        <ion-col class="ion-text-left" size="12"> &nbsp; </ion-col>
      </ion-row>
      <ion-row class="summary-container" style="height: 50%">
        <ion-col class="ion-text-left" size="12">
          <div class="summary-1 border-bottom" style="height: 20%">
            <div
              class="padding border-right"
              style="background: var(--color-coral); width: 34%"
            >
              <span class="attr">Members</span>
              <span class="val">{{ membersData.total }}</span>
            </div>
            <div
              class="padding"
              style="background: var(--color-yellow); width: 66%"
            >
              <span class="attr">Most rounds</span>
              <span class="val small"
                >{{ membersData.roundsPlayed[0][1] }} -
                {{ membersData.roundsPlayed[0][0] }}</span
              >
            </div>
          </div>
          <div class="summary-1" style="height: 80%">
            <div
              class="border-right"
              style="background: var(--color-gains); width: 60%"
            >
              <div
                class="summary-member-handicap-history"
                ref="summary-member-handicap-history"
              ></div>
            </div>
            <div style="width: 40%">
              <div class="border-bottom" style="height: 50%; width: 100%">
                <div style="background: var(--color-coral); width: 100%">
                  <div
                    class="summary-member-scoring-best"
                    ref="summary-member-scoring-best"
                  ></div>
                </div>
              </div>
              <div style="height: 50%; width: 100%">
                <div style="background: var(--color-pale); width: 100%">
                  <div
                    class="summary-member-scoring-avg"
                    ref="summary-member-scoring-avg"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </ion-col>
      </ion-row>
      <ion-row class="row-padding" style="height: 25%">
        <ion-col class="ion-text-left" size="12"> &nbsp; </ion-col>
      </ion-row>
    </div>
  </div>
</template>

<script>
import * as d3 from 'd3'
import _ from 'lodash'
import moment from 'moment'
import { onMounted, ref, getCurrentInstance } from 'vue'
import { IonRow, IonCol } from '@ionic/vue'
import { arrowDown } from 'ionicons/icons'
import stats from '@/modules/stats'
export default {
  components: {
    IonRow,
    IonCol,
  },
  setup() {
    const {
      data,
      membersData,
      members,
      displayHandicap,
      getDate,
      handicapConvert,
      shorten,
    } = stats()
    const root = ref(getCurrentInstance())
    onMounted(() => {
      setTimeout(() => {
        bg()
        handicapGraph({ style: 'summary-member-handicap-history' })
        scoringStats('summary-member-scoring-best', 'best')
        scoringStats('summary-member-scoring-avg', 'avg')
      }, 1000)
    })

    const bg = async () => {
      let style = 'share-slider-2'
      await d3.selectAll(`.${style} svg`).remove()
      let width = root.value.refs[style]
        ? root.value.refs[style].clientWidth
        : 375
      let height = root.value.refs[style]
        ? root.value.refs[style].clientHeight
        : 400

      const svg = d3
        .select(`.${style}`)
        .append('svg')
        .attr('width', width)
        .attr('height', height)

      for (let x = 0; x < 40; x++) {
        for (let y = 0; y < 40; y++) {
          svg
            .append('svg:image')
            .attr('width', 50)
            .attr('height', 50)
            .attr(
              'transform',
              `translate(${x * 75}, ${
                y * 100 + (x % 2 != 0 ? 50 : 0)
              }) rotate(-45)`
            )
            .attr('xlink:href', '/assets/icon.png')
            .attr('opacity', 0.1)
        }
      }
    }

    const handicapGraph = async ({ style }) => {
      let width = root.value.refs[style]
        ? root.value.refs[style].clientWidth
        : 375
      let height = root.value.refs[style]
        ? root.value.refs[style].clientHeight
        : 300
      await d3.selectAll(`.${style} svg`).remove()

      const svg = d3
        .select(`.${style}`)
        .append('svg')
        .attr('width', width)
        .attr('height', height)

      let x = d3
        .scaleTime()
        .domain(
          d3.extent(
            [
              getDate('2021-01-01 00:00', null),
              getDate('2021-12-31 23:59', null),
            ],
            (d) => d
          )
        )
        .range([width / 2.5, 2 * (width / 3)])

      let points = []
      Object.keys(membersData.value.handicaps).map((member, index) => {
        if (membersData.value.handicaps[member].scores.length) {
          points.push({
            ...membersData.value.handicaps[member],
            nickname: member,
          })
        }
      })

      points = _.orderBy(points, ['changeAbs'], ['desc']).slice(0, 10)

      let y = d3
        .scaleBand()
        .domain(points.map((d) => d.nickname))
        .range([25, height])

      let space = ((height - 25) / 10) * 0.5

      points.map((point) => {
        if (point.scores && point.scores.length) {
          let y1 = d3
            .scaleLinear()
            .domain([
              _.minBy(point.scores, 'Value').Value,
              _.maxBy(point.scores, 'Value').Value,
            ])
            .range([
              y(point.nickname) + space / 2,
              y(point.nickname) - space / 2,
            ])

          svg
            .append('path')
            .datum(point.scores)
            .attr(
              'class',
              `handicap-line ${point.change > 0 ? 'red' : 'green'}`
            )
            .attr(
              'd',
              d3
                .line()
                .x((d) => x(d.Date))
                .y((d) => y1(d.Value))
                .defined((d) => d.Value)
                .curve(d3.curveMonotoneX)
            )

          svg
            .append('text')
            .text(Math.abs(point.change).toFixed(1))
            .attr('x', width - 20)
            .attr('y', y(point.nickname))
            .attr('text-anchor', 'middle')
            .attr('class', `change ${point.change > 0 ? 'red' : 'green'}`)

          if (point.change < 0) {
            svg
              .append('line')
              .attr('x1', width - 10)
              .attr('x2', width - 10)
              .attr('y1', y(point.nickname) + space / 2 - 8)
              .attr('y2', y(point.nickname) + space / 2 - 14)
              .attr('class', `arrow ${point.change > 0 ? 'red' : 'green'}`)

            svg
              .append('line')
              .attr('x1', width - 10)
              .attr('x2', width - 12.5)
              .attr('y1', y(point.nickname) + space / 2 - 8)
              .attr('y2', y(point.nickname) + space / 2 - 10)
              .attr('class', `arrow ${point.change > 0 ? 'red' : 'green'}`)

            svg
              .append('line')
              .attr('x1', width - 10)
              .attr('x2', width - 7.5)
              .attr('y1', y(point.nickname) + space / 2 - 8)
              .attr('y2', y(point.nickname) + space / 2 - 10)
              .attr('class', `arrow ${point.change > 0 ? 'red' : 'green'}`)
          } else if (point.change > 0) {
            svg
              .append('line')
              .attr('x1', width - 10)
              .attr('x2', width - 10)
              .attr('y1', y(point.nickname) + space / 2 - 8)
              .attr('y2', y(point.nickname) + space / 2 - 14)
              .attr('class', `arrow ${point.change > 0 ? 'red' : 'green'}`)

            svg
              .append('line')
              .attr('x1', width - 10)
              .attr('x2', width - 12.5)
              .attr('y1', y(point.nickname) + space / 2 - 14)
              .attr('y2', y(point.nickname) + space / 2 - 12)
              .attr('class', `arrow ${point.change > 0 ? 'red' : 'green'}`)

            svg
              .append('line')
              .attr('x1', width - 10)
              .attr('x2', width - 7.5)
              .attr('y1', y(point.nickname) + space / 2 - 14)
              .attr('y2', y(point.nickname) + space / 2 - 12)
              .attr('class', `arrow ${point.change > 0 ? 'red' : 'green'}`)
          }

          svg
            .append('text')
            .text(point.exactHandicap)
            .attr('x', width - 40)
            .attr('y', y(point.nickname) + 2.5)
            .attr('text-anchor', 'middle')
            .attr('class', 'current')

          svg
            .append('text')
            .text(shorten(point.nickname, 9))
            .attr('x', 10)
            .attr('y', y(point.nickname) + 2.5)
            .attr('text-anchor', 'start')
            .attr('class', 'member-name')
        }
      })
    }

    const scoringStats = async (style, stat) => {
      let width = root.value.refs[style]
        ? root.value.refs[style].clientWidth
        : 300
      let height = root.value.refs[style]
        ? root.value.refs[style].clientHeight
        : 300
      await d3.selectAll(`.${style} svg`).remove()

      const svg = d3
        .select(`.${style}`)
        .append('svg')
        .attr('width', width)
        .attr('height', height)

      let mapping = ['stableford', 'topar', 'adjusted']

      Object.keys(membersData.value.scoring).map((nickname) => {
        membersData.value.scoring[nickname].nickname = nickname
      })

      svg
        .append('text')
        .text(stat == 'avg' ? 'Average' : 'Best')
        .attr('x', 15)
        .attr('y', 15)
        .attr('text-anchor', 'start')
        .attr('class', 'scoring-label-main')

      mapping.map((type, index) => {
        let best = 'low'
        if (type == 'stableford') best = 'high'

        let ordered = _.orderBy(
          _.filter(
            JSON.parse(JSON.stringify(membersData.value.scoring)),
            (o) => o[type].scores.length
          ),
          [`${type}.${stat == 'avg' ? stat : best}`],
          [best == 'high' ? 'desc' : 'asc']
        )

        svg
          .append('text')
          .text(type)
          .attr('x', 15)
          .attr('y', (index + 1) * (height / 4))
          .attr('text-anchor', 'start')
          .attr('class', 'scoring-label')

        svg
          .append('text')
          .html(
            `${ordered[0][type][stat == 'avg' ? stat : best].toFixed(
              1
            )} &nbsp; ${ordered[0].nickname}`
          )
          .attr('x', 15)
          .attr('y', (index + 1) * (height / 4) + 17.5)
          .attr('text-anchor', 'start')
          .attr('class', 'scoring-value')
      })
    }

    return {
      displayHandicap,
      data,
      members,
      membersData,
    }
  },
}
</script>

<style lang="scss" scoped>
.summary-container {
  overflow: hidden;
  ion-col {
    height: 100%;
  }

  .border-bottom {
    border-bottom: 2px solid var(--color-blue);
  }

  .border-right {
    border-right: 2px solid var(--color-blue);
  }

  .summary-1 {
    overflow: hidden;
    div {
      display: inline-block;
      height: 100%;
      float: left;
      overflow: hidden;

      &.padding {
        padding: 0.5rem 1rem;
      }
    }

    .attr {
      color: var(--color-blue);
      font-weight: 400;
      display: block;
      text-transform: uppercase;
      font-size: 0.7rem;
    }

    .val {
      color: var(--color-blue);
      font-weight: 600;
      display: block;
      font-size: 1.25rem;

      &.small {
        font-size: 1.25rem;
      }
    }
  }
}
</style>

<style lang="scss">
.summary-member-scoring-best {
  height: 100%;
  width: 100%;

  text {
    &.scoring-label-main {
      font-size: 0.75rem;
      font-weight: 600;
      text-transform: uppercase;
    }

    &.scoring-label {
      font-size: 0.55rem;
      font-weight: 400;
      text-transform: uppercase;
    }

    &.scoring-value {
      font-weight: 600;
      font-size: 0.75rem;
    }
  }
}

.summary-member-scoring-avg {
  height: 100%;
  width: 100%;

  text {
    &.scoring-label-main {
      font-size: 0.75rem;
      font-weight: 600;
      text-transform: uppercase;
    }

    &.scoring-label {
      font-size: 0.55rem;
      font-weight: 400;
      text-transform: uppercase;
    }

    &.scoring-value {
      font-weight: 600;
      font-size: 0.75rem;
    }
  }
}

.summary-member-handicap-history {
  height: 100%;
  width: 100%;

  text {
    fill: var(--color-blue);
    &.member-name {
      font-size: 0.75rem;
      font-weight: 600;
    }
    &.current {
      font-size: 0.75rem;
      font-weight: 600;
    }
    &.change {
      font-size: 0.5rem;
      font-weight: 400;
      &.red {
        fill: var(--color-red);
      }
      &.green {
        fill: var(--color-green);
      }
    }
  }

  line {
    &.arrow {
      &.red {
        stroke: var(--color-red);
      }
      &.green {
        stroke: var(--color-green);
      }
    }
  }

  .handicap-line {
    stroke-width: 1.5;
    fill: none;
    stroke: var(--color-orange);

    &.red {
      stroke: var(--color-red);
    }

    &.green {
      stroke: var(--color-green);
    }
  }
}
</style>
