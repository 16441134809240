<template>
  <div class="slide-container full-height bg-gains">
    <div class="slide-content">
      <ion-row class="row-padding" style="height: 10%">
        <ion-col class="ion-text-left" size="12">
          <span class="stat-sub-heading secondary">To Par stats</span>
        </ion-col>
      </ion-row>
      <ion-row style="height: 90%">
        <ion-col size="12">
          <div
            class="scoring-graph-members topar-stats-members"
            ref="topar-stats-members"
          ></div>
        </ion-col>
      </ion-row>
    </div>
  </div>
</template>

<script>
import * as d3 from 'd3'
import _ from 'lodash'
import moment from 'moment'
import { onMounted, ref, getCurrentInstance } from 'vue'
import { IonRow, IonCol } from '@ionic/vue'
import stats from '@/modules/stats'
export default {
  components: {
    IonRow,
    IonCol,
  },
  setup() {
    const { data, membersData, displayToPar, getDate, shorten } = stats()
    const root = ref(getCurrentInstance())
    onMounted(() => {
      setTimeout(() => {
        plot({
          array: 'topar',
          style: 'topar-stats-members',
          best: 'low',
        })
      }, 1000)
    })

    const plot = async ({ array, style, best }) => {
      let width = root.value.refs[style]
        ? root.value.refs[style].clientWidth
        : 375
      let height = root.value.refs[style]
        ? root.value.refs[style].clientHeight
        : 300
      await d3.selectAll(`.${style} svg`).remove()

      const svg = d3
        .select(`.${style}`)
        .append('svg')
        .attr('width', width)
        .attr('height', height)

      let x = d3
        .scaleTime()
        .domain(
          d3.extent(
            [
              getDate('2021-01-01 00:00', null),
              getDate('2021-12-31 23:59', null),
            ],
            (d) => d
          )
        )
        .range([width / 3, 2 * (width / 3)])

      let points = []
      Object.keys(membersData.value.scoring).map((member, index) => {
        points.push({
          ...membersData.value.scoring[member][array],
          nickname: member,
        })
      })

      points = _.orderBy(
        points.slice(0, 10),
        [best],
        [best == 'high' ? 'desc' : 'asc']
      )
      for (let i = 0; i < 10 - points.length; i++) {
        points.push({
          nickname: `empty${i}`,
        })
      }

      let y = d3
        .scaleBand()
        .domain(points.map((d) => d.nickname))
        .range([75, height - 25])

      let space = ((height - 100) / 10) * 0.45

      points.map((point) => {
        if (point.scores) {
          let y1 = d3
            .scaleLinear()
            .domain([point.low, point.high])
            .range([
              y(point.nickname) + space / 2,
              y(point.nickname) - space / 2,
            ])

          svg
            .selectAll('dots')
            .data(point.scores)
            .enter()
            .append('circle')
            .attr('r', (d) => (point[best] == d.Value ? 5 : 2.5))
            .attr('cx', (d) => x(d.Date))
            .attr('cy', (d) => y1(d.Value))
            .attr(
              'class',
              (d) =>
                `score-dot${point[best] == d.Value ? ' best-score' : ''}${
                  point[best == 'high' ? 'low' : 'high'] == d.Value
                    ? ' lowest-score'
                    : ''
                }`
            )

          svg
            .append('text')
            .text(point[best])
            .attr('x', width - 15)
            .attr('y', y(point.nickname))
            .attr('text-anchor', 'end')
            .attr('class', 'member-best')

          svg
            .append('text')
            .text(point.avg.toFixed(1))
            .attr('x', width - 65)
            .attr('y', y(point.nickname))
            .attr('text-anchor', 'end')
            .attr('class', 'member-avg')

          svg
            .append('text')
            .text(shorten(point.nickname, 10))
            .attr('x', 15)
            .attr('y', y(point.nickname))
            .attr('text-anchor', 'start')
            .attr('class', 'member-name')
        }
      })

      svg
        .append('text')
        .text('avg')
        .attr('x', width - 65)
        .attr('y', 30)
        .attr('text-anchor', 'end')
        .attr('class', 'axis-label')

      svg
        .append('text')
        .text('best')
        .attr('x', width - 15)
        .attr('y', 30)
        .attr('text-anchor', 'end')
        .attr('class', 'axis-label')

      svg
        .append('text')
        .text('Jan')
        .attr('x', (d) => x(getDate('2021-01-01 00:00')))
        .attr('y', 30)
        .attr('text-anchor', 'start')
        .attr('class', 'axis-label month')

      svg
        .append('text')
        .text('Dec')
        .attr('x', (d) => x(getDate('2021-12-31 23:59')))
        .attr('y', 30)
        .attr('text-anchor', 'end')
        .attr('class', 'axis-label month')
    }

    return {
      data,
    }
  },
}
</script>

<style lang="scss" scoped>
.secondary {
  color: var(--color-blue);
}
</style>
