<template>
  <div class="slide-container full-height bg-pale">
    <div class="slide-content">
      <ion-row class="row-padding" style="height: 40%">
        <ion-col class="ion-text-left" size="12">
          <span class="stat-heading secondary1">{{ membersData.total }}</span>
          <p>
            You have
            <span class="secondary1 sub-stat">{{ membersData.total }}</span>
            member{{ membersData.total > 1 ? 's' : '' }}.
            <span class="secondary1 sub-stat">{{
              membersData.roundsPlayed[0][0]
            }}</span>
            played
            <span class="secondary1 sub-stat">{{
              membersData.roundsPlayed[0][1]
            }}</span>
            rounds{{
              membersData.total > 1 ? ', the most played by all members' : ''
            }}.
          </p>
        </ion-col>
      </ion-row>
      <ion-row style="height: 60%">
        <ion-col size="12">
          <div class="rounds-members" ref="rounds-members"></div>
        </ion-col>
      </ion-row>
    </div>
  </div>
</template>

<script>
import * as d3 from 'd3'
import _ from 'lodash'
import moment from 'moment'
import { onMounted, ref, getCurrentInstance } from 'vue'
import { IonRow, IonCol } from '@ionic/vue'
import stats from '@/modules/stats'
export default {
  components: {
    IonRow,
    IonCol,
  },
  setup() {
    const { data, membersData, shorten } = stats()
    const root = ref(getCurrentInstance())
    onMounted(() => {
      setTimeout(() => {
        plot()
      }, 1000)
    })

    const plot = async () => {
      let style = 'rounds-members'
      await d3.selectAll(`.${style} svg`).remove()
      let width = root.value.refs[style]
        ? root.value.refs[style].clientWidth
        : 375
      let height = root.value.refs[style]
        ? root.value.refs[style].clientHeight
        : 400

      let points = []
      membersData.value.roundsPlayed.map((member) => {
        points.push({
          Nickname: member[0],
          Value: member[1],
        })
      })

      points = points.slice(0, 9)
      points.push({
        Nickname: 'You',
        Value: data.value.roundsPlayed[data.value.currentYear],
      })

      points = _.orderBy(points, ['Value'], ['desc'])

      const svg = d3
        .select(`.${style}`)
        .append('svg')
        .attr('width', width)
        .attr('height', height)

      let max = _.maxBy(points, 'Value').Value
      let min = [0, !max ? 1 : max]

      let x = d3
          .scaleLinear()
          .domain(min)
          .range([0, width - 55]),
        y = d3
          .scaleBand()
          .domain(points.map((d, index) => index))
          .range([50, height - 50])

      svg
        .selectAll('lines')
        .data(points)
        .enter()
        .append('rect')
        .attr('x', x(0))
        .attr('y', (d, index) => y(index))
        .attr('width', (d) => x(d.Value))
        .attr('height', 2)
        .attr('class', (d) => `month ${d.Nickname == 'You' ? ' self' : ''}`)

      svg
        .selectAll('months')
        .data(points)
        .enter()
        .append('text')
        .text((d) => shorten(d.Nickname, 9))
        .attr('x', 5)
        .attr('y', (d, index) => y(index) - 10)
        .attr('text-anchor', 'start')
        .attr('class', (d) => `month ${d.Nickname == 'You' ? ' self' : ''}`)

      svg
        .selectAll('dots')
        .data(points)
        .enter()
        .append('circle')
        .attr('r', 5)
        .attr('cx', (d, index) => x(d.Value))
        .attr('cy', (d, index) => y(index) + 0.5)
        .attr('class', (d) => `circle ${d.Nickname == 'You' ? ' self' : ''}`)

      svg
        .selectAll('rounds')
        .data(points)
        .enter()
        .append('text')
        .text((d) => d.Value)
        .attr('x', (d, index) => x(d.Value) + 10)
        .attr('y', (d, index) => y(index) + 6)
        .attr('text-anchor', 'start')
        .attr('class', (d) => `month${d.Nickname == 'You' ? ' self' : ''}`)
    }

    return {
      data,
      membersData,
    }
  },
}
</script>

<style lang="scss">
.rounds-members {
  width: 100%;
  height: 100%;
  overflow: hidden;

  text {
    &.month {
      fill: var(--color-blue);
      font-size: 0.75rem;

      &.self {
        fill: var(--color-green);
      }
      //font-weight: 700;

      //&.no-rounds {
      //  fill: var(--color-green);
      //}
    }
  }

  rect {
    fill: var(--color-blue);
    &.self {
      fill: var(--color-green);
    }
  }

  circle {
    fill: var(--color-blue);
    &.self {
      fill: var(--color-green);
    }
  }
}
</style>
