import * as d3 from 'd3'
import _ from 'lodash'
import moment from 'moment'

export default function () {
  const dots = async (root, number) => {
    await d3.selectAll(`.courses-played svg`).remove()
    let width = root.refs['courses-played']
      ? root.refs['courses-played'].clientWidth
      : 300
    let height = root.refs['courses-played']
      ? root.refs['courses-played'].clientHeight - 1
      : 300

    const svg = d3
      .select(`.courses-played`)
      .append('svg')
      .attr('width', width)
      .attr('height', height)

    let cols = 8
    if (number > 20 && number <= 40) cols = 9
    else if (number > 40 && number <= 60) cols = 10
    else if (number > 60 && number <= 80) cols = 11
    else if (number > 80 && number <= 100) cols = 12
    else if (number > 100) cols = 13

    let diameter = width / cols

    let row = 1
    let col = 1

    let colored = Math.floor(Math.random() * number) + 1

    for (let i = 1; i <= number; i++) {
      svg
        .append('g')
        .append('circle')
        .attr('class', colored == i ? 'secondary' : '')
        .attr('r', diameter / 2)
        .attr('cx', diameter / 2 + (col - 1) * diameter)
        .attr('cy', height - 10 - diameter * row)

      col++
      if (col > cols) {
        col = 1
        row++
      }
    }
  }

  const handicapConvert = (handicap) => {
    return parseFloat(handicap.replace('+', '-'))
  }

  const displayHandicap = (handicap) => {
    return handicap.toFixed(1).replace('-', '+')
  }

  const displayToPar = (handicap) => {
    if (handicap == 0) return 'E'
    if (handicap > 0) return `+${handicap.toFixed(1)}`
    return handicap.toFixed(1)
  }

  const toNumber = (handicap) => {
    return handicap.toString().includes('+')
      ? -1 * parseFloat(handicap)
      : parseFloat(handicap)
  }

  const getDate = (date, offset = 0) => {
    let year = moment(date).format('YYYY')
    let month = moment(date).format('M')
    let day = moment(date).format('D')
    let hour = moment(date).format('HH')
    let minute = moment(date).format('mm')
    let d = new Date(year, month - 1, day, hour, minute)
    d.setTime(d.getTime() + offset * (24 * 60 * 60 * 1000))
    return new Date(d)
  }

  const minMax = (data, by) => {
    return [_.minBy(data, by)[by], _.maxBy(data, by)[by]]
  }

  return {
    handicapConvert,

    minMax,
    toNumber,
    getDate,
    displayHandicap,
    displayToPar,

    dots,
  }
}
