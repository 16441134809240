<template>
  <div class="slide-container full-height bg-gains">
    <div class="slide-content">
      <ion-row class="row-padding" style="height: 10%">
        <ion-col class="ion-text-left" size="12">
          <span class="stat-heading">Scoring stats</span>
        </ion-col>
      </ion-row>
      <ion-row style="height: 90%">
        <ion-col size="12">
          <div
            class="scoring-graph stableford-stats"
            ref="stableford-stats"
          ></div>
          <div class="scoring-graph topar-stats" ref="topar-stats"></div>
        </ion-col>
      </ion-row>
    </div>
  </div>
</template>

<script>
import * as d3 from 'd3'
import _ from 'lodash'
import moment from 'moment'
import { onMounted, ref, getCurrentInstance } from 'vue'
import { IonRow, IonCol } from '@ionic/vue'
import stats from '@/modules/stats'
export default {
  components: {
    IonRow,
    IonCol,
  },
  setup() {
    const { data, displayToPar } = stats()
    const root = ref(getCurrentInstance())
    onMounted(() => {
      setTimeout(() => {
        plot({
          points: data.value.scoring.stableford,
          style: 'stableford-stats',
          best: 'high',
        })
        plot({
          points: data.value.scoring.topar,
          style: 'topar-stats',
          best: 'low',
        })
      }, 1000)
    })

    const plot = async ({ style, best, points }) => {
      let width = root.value.refs[style]
        ? root.value.refs[style].clientWidth
        : 375
      let height = root.value.refs[style]
        ? root.value.refs[style].clientHeight
        : 300
      await d3.selectAll(`.${style} svg`).remove()

      const svg = d3
        .select(`.${style}`)
        .append('svg')
        .attr('width', width)
        .attr('height', height)

      let min = [points.low, points.high]

      let x = d3
        .scaleTime()
        .domain(d3.extent(points.scores, (d) => d.Date))
        .range([50, width - 50])
      let y = d3
        .scaleLinear()
        .domain(min)
        .range([height - 50, 50])

      svg
        .append('text')
        .text(style.replace('-', ' ').replace('stats', ''))
        .attr('dy', 0)
        .attr('transform', `translate(20, ${height / 2}) rotate(-90)`)
        .attr('text-anchor', 'middle')
        .attr('class', 'graph-heading')

      svg
        .selectAll('dots')
        .data(points.scores)
        .enter()
        .append('circle')
        .attr('r', (d) => (points[best] == d.Value ? 10 : 5))
        .attr('cx', (d) => x(d.Date))
        .attr('cy', (d) => y(d.Value))
        .attr(
          'class',
          (d) => `score-dot${points[best] == d.Value ? ' min' : ''}`
        )

      svg
        .append('line')
        .attr('x1', 50)
        .attr('x2', width - 50)
        .attr('y1', y(points.avg))
        .attr('y2', y(points.avg))
        .attr('class', 'average-line')

      let labels = ['low', 'avg', 'high']
      labels.map((label) => {
        svg
          .append('text')
          .text(
            style == 'topar-stats'
              ? displayToPar(points[label])
              : points[label].toFixed(1)
          )
          .attr('x', width - 35)
          .attr('y', y(points[label]) + 5)
          .attr('text-anchor', 'start')
          .attr('class', 'axis-label')
      })
    }

    return {
      data,
    }
  },
}
</script>

<style lang="scss" scoped>
.stat-heading {
  font-size: 2rem !important;
  color: var(--color-blue);
}

.scoring-graph {
  height: 50%;
  width: 100%;
}
</style>

<style lang="scss">
.scoring-graph {
  height: 25%;
  width: 100%;

  .graph-heading {
    font-size: 0.9rem;
    font-weight: 700;
    text-transform: capitalize;
  }

  .axis-label {
    font-size: 0.7rem;
  }

  circle {
    &.score-dot {
      z-index: 50;
      &.min {
        z-index: 100;
      }
    }
  }

  &.stableford-stats {
    .score-dot {
      fill: rgba(0, 145, 110, 0.65);
    }

    line {
      stroke: rgba(0, 145, 110, 0.65);
    }

    text {
      fill: rgba(0, 145, 110, 1);
    }
  }

  &.topar-stats {
    .score-dot {
      fill: rgba(25, 42, 86, 0.65);
    }

    line {
      stroke: rgba(25, 42, 86, 0.65);
    }

    text {
      fill: rgba(25, 42, 86, 1);
    }
  }

  line {
    stroke-width: 1.5;
  }
}
</style>
