<template>
  <div class="scrollbar" v-if="slider">
    <div
      :class="`scrollbar-slide scrollbar-slide-${index}`"
      :key="`scrollbar-slide-${index}`"
      :style="{
        width: `${(100 - slider.slides.length * 2) / slider.slides.length}%`,
      }"
      v-for="index in slider.slides.length"
    >
      <div
        class="timer"
        :style="{
          width: timerWidth[index - 1].width,
        }"
        v-if="index == 1 || slider.activeIndex >= index - 1"
      >
        &nbsp;
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, onMounted } from 'vue'
import moment from 'moment'
export default {
  props: {
    slider: {
      type: Object,
      default: () => {},
    },
    container: {
      type: Object,
      default: () => {},
    },
    slide: {
      type: Object,
      default: () => {},
    },
    tap: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const now = ref(moment())

    onMounted(() => {
      setInterval(() => {
        if (props.slider.activeIndex > 0) now.value = moment()
      }, 750)
    })

    const timerWidth = computed(() => {
      let slides = []
      for (let i = 0; i < props.slider.slides.length; i++) {
        let width
        if (i == 0) width = 100
        else if (props.slider.activeIndex > i) width = 100
        else if (props.slider.activeIndex == i && props.slide.change) width = 0
        else {
          let current = now.value
          if (props.tap.start) current = props.tap.start
          width =
            (moment(current).diff(props.slide.started, 'milliseconds') /
              10000) *
            100
        }
        slides.push({
          width: `${width}%`,
        })
      }
      return slides
    })

    return {
      timerWidth,
      width: props.container.clientWidth,
    }
  },
}
</script>

<style lang="scss" scoped>
.scrollbar {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  padding: 0.5rem;
  height: 20px;
  z-index: 9999;

  .scrollbar-slide {
    border-radius: 5px;
    height: 100%;
    float: left;
    margin: 0 1%;
    overflow: hidden;

    background: rgba(0, 0, 0, 0.35);

    .timer {
      height: 100%;
      background: #fff;
    }
  }
}
</style>
