import _ from 'lodash'
import moment from 'moment'
import { reactive, toRefs } from 'vue'

let currentYear = moment().format('YYYY') - 1

const state = reactive({
  hash: null,
  shared: false,
  user: null,
  members: null,
  data: {
    currentYear,
    roundsPlayed: {
      [currentYear - 1]: 0,
      [currentYear]: 0,
    },
    roundsMonthly: {},
    lastRound: null,
    favouriteCourse: null,
    exactHandicap: null,
    handicapHistory: {
      scores: [],
    },
    scoring: {
      adjusted: {
        scores: [],
      },
      stableford: {
        scores: [],
      },
      topar: {
        scores: [],
      },
    },
  },
  membersData: {
    scoring: {},
    handicaps: {},
    handicapData: {},
    total: 0,
    roundsPlayed: {},
    averageHandicap: null,
  },
})

export default function () {
  const shorten = (word, length) => {
    if (word.length > length) return `${word.slice(0, length)}...`
    return word
  }

  const handicapConvert = (handicap) => {
    return parseFloat(handicap.toString().replace('+', '-'))
  }

  const getDate = (date, offset = 0) => {
    let year = moment(date).format('YYYY')
    let month = moment(date).format('M')
    let day = moment(date).format('D')
    let hour = moment(date).format('HH')
    let minute = moment(date).format('mm')
    let d = new Date(year, month - 1, day, hour, minute)
    d.setTime(d.getTime() + offset * (24 * 60 * 60 * 1000))
    return new Date(d)
  }

  const displayHandicap = (handicap) => {
    if (handicap) return handicap.toFixed(1).replace('-', '+')
    else return handicap
  }

  const displayToPar = (handicap) => {
    if (handicap == 0) return 'E'
    if (handicap > 0) return `+${handicap.toFixed(1)}`
    return handicap.toFixed(1)
  }

  const generate = () => {
    let rounds = _.cloneDeep(state.user.rounds)

    if (rounds && rounds.length) {
      state.data.handicapHistory.scores = []
      let courses = {}
      _.reverse(JSON.parse(JSON.stringify(rounds))).map((round) => {
        let year = moment(round.dateHeld).format('YYYY')
        if (year >= currentYear - 1 && year <= currentYear) {
          if (year < currentYear) state.data.lastRound = year
          else {
            let month = moment(round.dateHeld).format('M')
            if (!state.data.roundsMonthly[month])
              state.data.roundsMonthly[month] = 0
            state.data.roundsMonthly[month]++

            // COURSES
            if (!courses[round.location]) courses[round.location] = 0
            courses[round.location]++
            // STATS
            round.adjustedGross = parseInt(round.adjustedGross)
            round.competitionScore = parseInt(round.competitionScore)
            round.playedTo = handicapConvert(round.playedTo)
            round.newHandicap = handicapConvert(round.newHandicap)

            if (!isNaN(round.newHandicap)) {
              state.data.exactHandicap = round.newHandicap
              state.data.handicapHistory.scores.push({
                Date: getDate(round.dateHeld, null),
                Value: round.newHandicap,
                date: round.dateHeld,
              })
            }

            if (round.isNineHoleScore == false) {
              if (!isNaN(round.adjustedGross)) {
                state.data.scoring.adjusted.scores.push({
                  Date: getDate(round.dateHeld, null),
                  Value: round.adjustedGross,
                })
              }

              if (
                !isNaN(round.competitionScore) &&
                round.competitionType == 'Stableford'
              ) {
                state.data.scoring.stableford.scores.push({
                  Date: getDate(round.dateHeld, null),
                  Value: round.competitionScore,
                })
              }

              if (!isNaN(round.playedTo)) {
                state.data.scoring.topar.scores.push({
                  Date: getDate(round.dateHeld, null),
                  Value: round.playedTo,
                })
              }
            }
          }

          // ROUND
          state.data.roundsPlayed[year]++
        }
      })

      let last = null
      let sortedRounds = []
      state.data.handicapHistory.scores.map((round, index) => {
        if (
          last &&
          moment(last.date).format('M') != moment(round.date).format('M')
        ) {
          sortedRounds.push({
            Date: getDate(`2021-${moment(round.date).format('MM')}-01 00:00`),
            Value: last.Value,
          })
        }

        sortedRounds.push({
          Date: getDate(round.date),
          Value: round.Value,
        })
        if (state.data.handicapHistory.scores.length - 1 == index) {
          sortedRounds.push({
            Date: getDate('2021-12-31 23:59'),
            Value: round.Value,
          })
        } else if (index == 0) {
          let newVal = round.Value
          if (
            state.data.lastRound &&
            !isNaN(state.data.lastRound.newHandicap)
          ) {
            newVal = handicapConvert(state.data.lastRound.newHandicap)
          }
          sortedRounds.unshift({
            Date: getDate('2021-01-01 00:00'),
            Value: newVal,
          })
        }
        last = round
      })

      state.data.handicapHistory.scores = sortedRounds

      let sortedCourses = _.sortBy(Object.entries(courses), [(o) => o[1]])
      if (sortedCourses.length)
        state.data.favouriteCourse = sortedCourses[sortedCourses.length - 1][0]

      let handicapHistory = state.data.handicapHistory.scores
      let low = _.minBy(handicapHistory, 'Value')
      let high = _.maxBy(handicapHistory, 'Value')

      state.data.handicapHistory = {
        scores: handicapHistory,
        low: low ? low.Value : null,
        avg: handicapHistory ? _.meanBy(handicapHistory, 'Value') : null,
        high: high ? high.Value : null,
      }

      Object.keys(state.data.scoring).map((key) => {
        let scores = state.data.scoring[key].scores
        let low = _.minBy(scores, 'Value')
        let high = _.maxBy(scores, 'Value')

        state.data.scoring[key] = {
          scores,
          low: low ? low.Value : null,
          avg: scores ? _.meanBy(scores, 'Value') : null,
          high: high ? high.Value : null,
        }
      })
    }
  }

  const generateMembers = () => {
    let memberHandicap = []

    if (state.members && state.members.length) {
      state.members.map((member) => {
        let lastRound = null
        state.membersData.total++

        member.nickname = _.find(
          state.user.members,
          (o) => o.golfLinkNo == member.details.golfLinkNo
        ).nickname

        if (
          member.rounds &&
          member.rounds.length &&
          member.details.exactHandicap
        ) {
          state.membersData.roundsPlayed[member.nickname] = 0
          state.membersData.scoring[member.nickname] = {
            adjusted: {
              scores: [],
            },
            stableford: {
              scores: [],
            },
            topar: {
              scores: [],
            },
          }

          state.membersData.handicaps[member.nickname] = {
            scores: [],
            exactHandicap: null,
            change: null,
          }

          _.reverse(JSON.parse(JSON.stringify(member.rounds))).map((round) => {
            let year = moment(round.dateHeld).format('YYYY')
            if (year >= currentYear - 1 && year <= currentYear) {
              if (year < currentYear) lastRound = round
              else {
                state.membersData.roundsPlayed[member.nickname]++

                // STATS
                round.adjustedGross = parseInt(round.adjustedGross)
                round.competitionScore = parseInt(round.competitionScore)
                round.playedTo = handicapConvert(round.playedTo)
                round.newHandicap = handicapConvert(round.newHandicap)

                if (!isNaN(round.newHandicap)) {
                  memberHandicap.push(round.newHandicap)
                  state.membersData.handicaps[member.nickname].exactHandicap =
                    round.newHandicap
                  state.membersData.handicaps[member.nickname].scores.push({
                    Date: getDate(round.dateHeld, null),
                    Value: round.newHandicap,
                    date: round.dateHeld,
                  })
                }

                if (round.isNineHoleScore == false) {
                  if (!isNaN(round.adjustedGross)) {
                    state.membersData.scoring[
                      member.nickname
                    ].adjusted.scores.push({
                      Date: getDate(round.dateHeld, null),
                      Value: round.adjustedGross,
                    })
                  }

                  if (
                    !isNaN(round.competitionScore) &&
                    round.competitionType == 'Stableford'
                  ) {
                    state.membersData.scoring[
                      member.nickname
                    ].stableford.scores.push({
                      Date: getDate(round.dateHeld, null),
                      Value: round.competitionScore,
                    })
                  }

                  if (!isNaN(round.playedTo)) {
                    state.membersData.scoring[
                      member.nickname
                    ].topar.scores.push({
                      Date: getDate(round.dateHeld, null),
                      Value: round.playedTo,
                    })
                  }
                }
              }
            }
          })

          let last = null
          let sortedRounds = []

          state.membersData.handicaps[member.nickname].scores.map(
            (round, index) => {
              if (
                last &&
                moment(last.date).format('M') != moment(round.date).format('M')
              ) {
                sortedRounds.push({
                  Date: getDate(
                    `2021-${moment(round.date).format('MM')}-01 00:00`
                  ),
                  Value: last.Value,
                })
              }

              sortedRounds.push({
                Date: getDate(round.date),
                Value: round.Value,
              })
              if (state.data.handicapHistory.scores.length - 1 == index) {
                sortedRounds.push({
                  Date: getDate('2021-12-31 23:59'),
                  Value: round.Value,
                })
              } else if (index == 0) {
                let newVal = round.Value
                if (lastRound && !isNaN(lastRound.newHandicap)) {
                  newVal = handicapConvert(lastRound.newHandicap)
                }
                sortedRounds.unshift({
                  Date: getDate('2021-01-01 00:00'),
                  Value: newVal,
                })
              }
              last = round
            }
          )

          state.membersData.handicaps[member.nickname].scores = _.orderBy(
            sortedRounds,
            ['Date'],
            ['asc']
          )
          let change = null
          if (
            state.membersData.handicaps[member.nickname].scores &&
            state.membersData.handicaps[member.nickname].scores.length
          ) {
            change =
              state.membersData.handicaps[member.nickname].scores[
                state.membersData.handicaps[member.nickname].scores.length - 1
              ].Value -
              state.membersData.handicaps[member.nickname].scores[0].Value
            state.membersData.handicaps[member.nickname].change = change
            state.membersData.handicaps[member.nickname].changeAbs =
              Math.abs(change)
          }

          Object.keys(state.membersData.scoring[member.nickname]).map((key) => {
            let scores = state.membersData.scoring[member.nickname][key].scores
            let low = _.minBy(scores, 'Value')
            let high = _.maxBy(scores, 'Value')

            state.membersData.scoring[member.nickname][key] = {
              scores,
              low: low ? low.Value : null,
              avg: scores ? _.meanBy(scores, 'Value') : null,
              high: high ? high.Value : null,
            }
          })

          if (
            state.membersData.handicaps[member.nickname].scores &&
            state.membersData.handicaps[member.nickname].scores.length
          ) {
            state.membersData.handicaps[member.nickname].scores.push({
              Date: getDate('2021-12-31 23:59', null),
              Value:
                state.membersData.handicaps[member.nickname].scores[
                  state.membersData.handicaps[member.nickname].scores.length - 1
                ].Value,
              date: '2021-12-31 23:59',
            })
          }
        }
      })

      state.membersData.roundsPlayed = _.sortBy(
        Object.entries(state.membersData.roundsPlayed),
        (o) => -o[1]
      )

      state.membersData.averageHandicap = _.mean(memberHandicap)
      state.membersData.handicapData = _.sortBy(
        Object.entries(state.membersData.handicaps),
        (o) => o[1].exactHandicap
      )
    }
  }

  return {
    ...toRefs(state),
    displayToPar,
    displayHandicap,
    handicapConvert,
    getDate,
    generate,
    generateMembers,
    shorten,
  }
}
