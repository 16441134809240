<template>
  <div class="login">
    <div class="bg">
      <div class="overlay"></div>
      <div class="content-container">
        <div class="content">
          <ion-row class="logo">
            <ion-col size="12" class="ion-text-center ion-align-self-center">
              <img src="/assets/logo-full.png" />
            </ion-col>
          </ion-row>
          <ion-row class="form">
            <ion-col size="12" class="ion-text-center ion-align-self-center">
              <div class="generating" v-if="generating">
                <ion-progress-bar
                  type="indeterminate"
                  color="light"
                ></ion-progress-bar>
                <span class="text">{{ generateText }}</span>
              </div>
              <div class="form" v-else>
                <ion-row>
                  <ion-col size="12" v-if="error">
                    <span class="error">{{ error }}</span>
                  </ion-col>
                  <ion-col size="12">
                    <ion-item class="input">
                      <ion-label position="floating">Email</ion-label>
                      <ion-input
                        type="email"
                        :value="email"
                        @ionChange="email = $event.target.value"
                      ></ion-input>
                    </ion-item>
                  </ion-col>
                  <ion-col size="12">
                    <ion-item class="input">
                      <ion-label position="floating">Handicap No.</ion-label>
                      <ion-input
                        type="text"
                        :value="golfLinkNo"
                        @ionChange="golfLinkNo = $event.target.value"
                      ></ion-input>
                    </ion-item>
                  </ion-col>
                  <ion-col size="12">
                    <ion-button
                      class="login-button"
                      expand="block"
                      @click="submit()"
                      :disabled="loading"
                    >
                      <ion-spinner name="dots" v-if="loading"></ion-spinner>
                      <span v-else>Login</span>
                    </ion-button>
                  </ion-col>
                </ion-row>
              </div>
            </ion-col>
          </ion-row>
          <ion-row class="app" v-if="!generating">
            <ion-col size="12" class="ion-text-center ion-align-self-center">
              <p style="padding: 1rem; color: #fff">
                Discover your golf stats from the last year with The Clubhouse
              </p>
            </ion-col>
          </ion-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
import {
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonSpinner,
  IonProgressBar,
} from '@ionic/vue'
import { defineComponent, ref, onMounted, toRefs, reactive } from 'vue'
import { useRoute } from 'vue-router'
import stats from '@/modules/stats'
import jwt from 'jsonwebtoken'
import moment from 'moment'

export default defineComponent({
  components: {
    IonRow,
    IonCol,
    IonItem,
    IonLabel,
    IonInput,
    IonButton,
    IonSpinner,
    IonProgressBar,
  },
  setup() {
    const route = useRoute()
    const query = ref(route.query.sm)
    const { shared, user, members, hash, generate, generateMembers } = stats()
    const generating = ref(false)
    const form = reactive({
      email: '',
      golfLinkNo: '',
      hash: null,
      error: null,
      loading: false,
    })

    const generateText = ref('Generating stats')
    const loadingText = [
      'Booking annual golf trip',
      'Entering monthly medal',
      'Renewing club membership',
      'Snack from halfway hut',
      'Packing Lunch',
      'Booking tee time',
      'Raking Bunker',
      'Post round drinks',
      'Generating stats',
    ]
    onMounted(async () => {
      setInterval(() => {
        generateText.value = loadingText[_.random(0, loadingText.length - 1)]
      }, 2000)

      if (query.value) {
        form.hash = query.value.split('IFJS')[0]
        if (form.hash == '123test') {
          generating.value = true
          setTimeout(() => {
            user.value = require('../../public/assets/d.json')
            members.value = require('../../public/assets/e.json')
            hash.value = form.hash
            generate()
            generateMembers()
            generating.value = false
          }, 1500)
        } else {
          generating.value = true
          await submit()
          generating.value = false
        }
      }
    })

    const submit = async () => {
      shared.value = !form.email
      form.error = null
      form.loading = true
      try {
        user.value = await axios({
          method: 'POST',
          data: form,
          url: `${process.env.VUE_APP_API_URL}/api/stats`,
          headers: {
            'x-api-key': await sign(),
          },
        })
          .then((response) => {
            return response.data
          })
          .catch((error) => {
            return { error: error.response.data }
          })
      } catch (err) {
        form.loading = false
        form.error = 'Email/Handicap not found'
        return
      }

      form.loading = false
      if (user.value.error) {
        form.error = 'Email/Handicap not found'
        return
      }

      generating.value = true
      if (user.value.members.length && user.value.members.length <= 50) {
        try {
          members.value = await axios({
            method: 'POST',
            url: `${process.env.VUE_APP_API_URL}/api/stats/members`,
            data: {
              handicaps: _.map(user.value.members, 'golfLinkNo'),
            },
            headers: {
              'x-api-key': await sign(),
            },
          })
            .then((response) => {
              return response.data
            })
            .catch((error) => {
              return error.response.data
            })
        } catch (err) {
          generating.value = false
          form.error = 'Member error, please contact help@theclubhouse.ai'
          return
        }
      } else if (user.value.members.length && user.value.members.length > 5) {
        try {
          let batches = []
          let batch = []
          let numbers = _.map(user.value.members, 'golfLinkNo')
          numbers.map((number) => {
            batch.push(number)
            if (batch.length == 5) {
              batches.push(batch)
              batch = []
            }
          })
          if (batch.length) {
            batches.push(batch)
            batch = []
          }

          members.value = []
          for (let i = 0; i < batches.length; i++) {
            let ress = await axios({
              method: 'POST',
              url: `${process.env.VUE_APP_API_URL}/api/stats/members`,
              data: {
                handicaps: batches[i],
              },
              headers: {
                'x-api-key': await sign(),
              },
            })
              .then((response) => {
                return response.data
              })
              .catch((error) => {
                return error.response.data
              })

            if (ress && !ress.error) members.value = members.value.concat(ress)
          }
        } catch (err) {
          generating.value = false
          form.error = 'Member error, please contact help@theclubhouse.ai'
          return
        }
      }

      generate()
      generateMembers()
      generating.value = false
      if (members.value.error) {
        form.error = 'Stat generation error'
        return
      }

      hash.value = user.value.hash
    }

    const sign = async () => {
      return jwt.sign(
        { timestamp: moment().format('X') },
        process.env.VUE_APP_SECRET
      )
    }

    return {
      // FUNCTIONS
      submit,
      // VARS
      ...toRefs(form),
      generateText,
      generating,
    }
  },
})
</script>

<style lang="scss" scoped>
.login {
  width: 100%;
  height: 100%;
  .bg {
    background: url('/assets/bg.jpg') no-repeat 50% 50%;
    background-size: cover;
    width: 100%;
    height: 100%;
    .overlay {
      background: rgba(0, 0, 0, 0.65);
      width: 100%;
      height: 100%;
    }

    .content-container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 9999;

      .content {
        height: 100%;
        width: 375px;
        margin: 0 auto;

        @media screen and (max-width: 375px) {
          left: 0;
          width: 100%;
        }

        ion-row {
          &.logo {
            height: 25%;
            img {
              height: 50px;
            }
          }

          &.form {
            height: 50%;

            .generating {
              width: 50%;
              text-align: center;
              margin: 0 auto;

              .text {
                color: #fff;
                display: block;
                padding: 1rem;
              }
            }
          }

          &.create {
            height: 25%;
          }
        }
      }
    }
  }

  form {
    ion-col {
      margin-bottom: 10px;
    }
  }

  .error {
    display: block;
    color: var(--color-red);
    font-size: 1.15rem;
    margin-bottom: 1rem;
  }
}
</style>
