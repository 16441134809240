<template>
  <div class="slide-container full-height bg-coral">
    <div class="slide-content">
      <ion-row class="row-padding" style="height: 40%">
        <ion-col class="ion-text-left" size="12">
          <span class="stat-heading secondary">{{
            displayHandicap(membersData.averageHandicap)
          }}</span>
          <p v-if="members && members.length">
            The average handicap of your members is
            <span class="sub-stat secondary">{{
              displayHandicap(membersData.averageHandicap)
            }}</span
            >.
            <span class="sub-stat secondary">{{
              membersData.handicapData[0][0]
            }}</span>
            has the lowest handicap at
            <span class="sub-stat secondary">{{
              displayHandicap(membersData.handicapData[0][1].exactHandicap)
            }}</span>
            and
            <span class="sub-stat secondary">{{
              membersData.handicapData[membersData.handicapData.length - 1][0]
            }}</span>
            has the highest handicap at
            <span class="sub-stat secondary">{{
              displayHandicap(
                membersData.handicapData[membersData.handicapData.length - 1][1]
                  .exactHandicap
              )
            }}</span
            >.
          </p>
          <p v-else>
            The average handicap of your members is
            <span class="sub-stat secondary">{{
              displayHandicap(membersData.averageHandicap)
            }}</span
            >.
          </p>
        </ion-col>
      </ion-row>
      <ion-row style="height: 60%">
        <ion-col size="12">
          <div
            class="handicap-history-members"
            ref="handicap-history-members"
          ></div>
        </ion-col>
      </ion-row>
    </div>
  </div>
</template>

<script>
import * as d3 from 'd3'
import _ from 'lodash'
import moment from 'moment'
import { onMounted, ref, getCurrentInstance } from 'vue'
import { IonRow, IonCol } from '@ionic/vue'
import stats from '@/modules/stats'
export default {
  components: {
    IonRow,
    IonCol,
  },
  setup() {
    const {
      data,
      membersData,
      members,
      displayHandicap,
      getDate,
      handicapConvert,
      shorten,
    } = stats()
    const root = ref(getCurrentInstance())
    onMounted(() => {
      setTimeout(() => {
        plot({
          style: 'handicap-history-members',
        })
      }, 1000)
    })

    const plot = async ({ style }) => {
      let width = root.value.refs[style]
        ? root.value.refs[style].clientWidth
        : 375
      let height = root.value.refs[style]
        ? root.value.refs[style].clientHeight
        : 300
      await d3.selectAll(`.${style} svg`).remove()

      const svg = d3
        .select(`.${style}`)
        .append('svg')
        .attr('width', width)
        .attr('height', height)

      let x = d3
        .scaleTime()
        .domain(
          d3.extent(
            [
              getDate('2021-01-01 00:00', null),
              getDate('2021-12-31 23:59', null),
            ],
            (d) => d
          )
        )
        .range([width / 2, 2 * (width / 2.75)])

      let points = []
      Object.keys(membersData.value.handicaps).map((member, index) => {
        if (membersData.value.handicaps[member].scores.length) {
          points.push({
            ...membersData.value.handicaps[member],
            nickname: member,
          })
        }
      })

      points = _.orderBy(points, ['changeAbs'], ['desc']).slice(0, 5)
      for (let i = 0; i < 10 - points.length; i++) {
        points.unshift({
          nickname: `empty${i}`,
        })
      }

      let y = d3
        .scaleBand()
        .domain(points.map((d) => d.nickname))
        .range([75, height - 15])

      let space = ((height - 100) / 10) * 0.45

      points.map((point) => {
        if (point.scores && point.scores.length) {
          let y1 = d3
            .scaleLinear()
            .domain([
              _.minBy(point.scores, 'Value').Value,
              _.maxBy(point.scores, 'Value').Value,
            ])
            .range([
              y(point.nickname) + space / 2,
              y(point.nickname) - space / 2,
            ])

          svg
            .append('path')
            .datum(point.scores)
            .attr(
              'class',
              `handicap-line ${point.change > 0 ? 'red' : 'green'}`
            )
            .attr(
              'd',
              d3
                .line()
                .x((d) => x(d.Date))
                .y((d) => y1(d.Value))
                .defined((d) => d.Value)
                .curve(d3.curveMonotoneX)
            )

          svg
            .append('text')
            .text(Math.abs(point.change).toFixed(1))
            .attr('x', width - 30)
            .attr('y', y(point.nickname))
            .attr('text-anchor', 'middle')
            .attr('class', `change ${point.change > 0 ? 'red' : 'green'}`)

          if (point.change < 0) {
            svg
              .append('line')
              .attr('x1', width - 17.5)
              .attr('x2', width - 17.5)
              .attr('y1', y(point.nickname) + space / 2 - 8)
              .attr('y2', y(point.nickname) + space / 2 - 14)
              .attr('class', `arrow ${point.change > 0 ? 'red' : 'green'}`)

            svg
              .append('line')
              .attr('x1', width - 17.5)
              .attr('x2', width - 15)
              .attr('y1', y(point.nickname) + space / 2 - 8)
              .attr('y2', y(point.nickname) + space / 2 - 10)
              .attr('class', `arrow ${point.change > 0 ? 'red' : 'green'}`)

            svg
              .append('line')
              .attr('x1', width - 17.5)
              .attr('x2', width - 20)
              .attr('y1', y(point.nickname) + space / 2 - 8)
              .attr('y2', y(point.nickname) + space / 2 - 10)
              .attr('class', `arrow ${point.change > 0 ? 'red' : 'green'}`)
          } else if (point.change > 0) {
            svg
              .append('line')
              .attr('x1', width - 17.5)
              .attr('x2', width - 17.5)
              .attr('y1', y(point.nickname) + space / 2 - 8)
              .attr('y2', y(point.nickname) + space / 2 - 14)
              .attr('class', `arrow ${point.change > 0 ? 'red' : 'green'}`)

            svg
              .append('line')
              .attr('x1', width - 17.5)
              .attr('x2', width - 15)
              .attr('y1', y(point.nickname) + space / 2 - 14)
              .attr('y2', y(point.nickname) + space / 2 - 12)
              .attr('class', `arrow ${point.change > 0 ? 'red' : 'green'}`)

            svg
              .append('line')
              .attr('x1', width - 17.5)
              .attr('x2', width - 20)
              .attr('y1', y(point.nickname) + space / 2 - 14)
              .attr('y2', y(point.nickname) + space / 2 - 12)
              .attr('class', `arrow ${point.change > 0 ? 'red' : 'green'}`)
          }

          svg
            .append('text')
            .text(point.exactHandicap)
            .attr('x', width - 45)
            .attr('y', y(point.nickname))
            .attr('text-anchor', 'end')
            .attr('class', 'current')

          svg
            .append('text')
            .text(shorten(point.nickname, 12))
            .attr('x', 15)
            .attr('y', y(point.nickname))
            .attr('text-anchor', 'start')
            .attr('class', 'member-name')
        }
      })
    }

    return {
      displayHandicap,
      data,
      members,
      membersData,
    }
  },
}
</script>

<style lang="scss" scoped>
.secondary {
  color: var(--color-blue);
}
</style>

<style lang="scss">
.handicap-history-members {
  height: 100%;
  width: 100%;

  text {
    fill: var(--color-blue);
    &.member-name {
      font-size: 0.85rem;
      font-weight: 600;
    }
    &.current {
      font-size: 0.85rem;
      font-weight: 600;
    }
    &.change {
      font-size: 0.65rem;
      font-weight: 400;
      &.red {
        fill: var(--color-red);
      }
      &.green {
        fill: var(--color-green);
      }
    }
  }

  line {
    &.arrow {
      &.red {
        stroke: var(--color-red);
      }
      &.green {
        stroke: var(--color-green);
      }
    }
  }

  .handicap-line {
    stroke-width: 1.5;
    fill: none;
    stroke: var(--color-orange);

    &.red {
      stroke: var(--color-red);
    }

    &.green {
      stroke: var(--color-green);
    }
  }
}
</style>
