<template>
  <div
    class="slide-container full-height bg-blue share-slider-3"
    ref="share-slider-3"
  >
    <div class="slide-content">
      <ion-row class="row-padding" style="height: 50%">
        <ion-col class="ion-text-left" size="12">
          <p class="large">Lets make 2022 even better!</p>
          <p>
            <span class="secondary">1.</span> Discover your #yearingolf 2021.
            <a href="https://yearingolf.theclubhouse.ai" target="_blank"
              >Create</a
            >.
          </p>
          <p>
            <span class="secondary">2.</span> Follow
            <a href="https://instagram.com/theclubhouseapp" target="_blank"
              >@theclubhouseapp</a
            >
            on Facebook and Instagram.
          </p>
          <p>
            <span class="secondary">3.</span> Share on social media, tag
            <a href="https://instagram.com/theclubhouseapp" target="_blank"
              >@theclubhouseapp</a
            >
            and a friend.
          </p>
          <p>
            <span class="secondary"
              >Have a chance to win a set of custom headcovers from
              <a href="https://instagram.com/theclubhouseapp" target="_blank"
                >Arayne Golf.</a
              ></span
            >
          </p>
        </ion-col>
      </ion-row>
    </div>
  </div>
</template>

<script>
import copy from 'copy-to-clipboard'
import * as d3 from 'd3'
import _ from 'lodash'
import moment from 'moment'
import { onMounted, ref, getCurrentInstance } from 'vue'
import { IonRow, IonCol, IonIcon } from '@ionic/vue'
import { copyOutline } from 'ionicons/icons'
import stats from '@/modules/stats'
export default {
  components: {
    IonRow,
    IonCol,
    IonIcon,
  },
  setup() {
    const copied = ref(false)
    const { hash, data } = stats()
    const root = ref(getCurrentInstance())
    const displayLink = ref(
      `https://yearingolf.theclubhouse.ai/?sm=${hash.value}IFJS${_.random(
        999999,
        99999999999999999
      )}`
    )
    onMounted(() => {
      setTimeout(() => {
        bg()
      }, 1000)
    })

    const bg = async () => {
      let style = 'share-slider-3'
      await d3.selectAll(`.${style} svg`).remove()
      let width = root.value.refs[style]
        ? root.value.refs[style].clientWidth
        : 375
      let height = root.value.refs[style]
        ? root.value.refs[style].clientHeight
        : 400

      const svg = d3
        .select(`.${style}`)
        .append('svg')
        .attr('width', width)
        .attr('height', height)

      for (let x = 0; x < 40; x++) {
        for (let y = 0; y < 40; y++) {
          svg
            .append('svg:image')
            .attr('width', 50)
            .attr('height', 50)
            .attr(
              'transform',
              `translate(${x * 75}, ${
                y * 100 + (x % 2 != 0 ? 50 : 0)
              }) rotate(-45)`
            )
            .attr('xlink:href', '/assets/icon.png')
            .attr('opacity', 0.05)
        }
      }
    }

    const shareLink = () => {
      copy(displayLink.value)
      copied.value = true
      setTimeout(() => {
        copied.value = false
      }, 500)
    }

    return {
      copied,
      shareLink,
      copyOutline,
      hash,
      displayLink,
    }
  },
}
</script>
<style lang="scss" scoped>
p {
  &.large {
    font-size: 2rem !important;
    font-weight: 800;
  }
  font-size: 1rem !important;
  font-weight: 300;
  a {
    color: var(--color-yellow);
  }
  .secondary {
    font-weight: 500;
  }
}
.copy-link {
  .link {
    display: inline-block;
    width: 85%;
    height: 40px;
    background: #d8d8d8;
    border-radius: 5px;
    overflow: hidden;
    color: var(--color-blue);
    line-height: 40px;
    padding: 0 10px;
    float: left;
  }
  .button {
    display: inline-block;
    height: 40px;
    width: 15%;
    float: right;
    .inner {
      margin-left: 15%;
      display: inline-block;
      height: 40px;
      width: 85%;
      background: var(--color-yellow);
      border-radius: 5px;
      padding: 5px;

      ion-icon {
        width: 100%;
        vertical-align: middle;
        color: var(--color-blue);
      }
    }
  }
}
</style>
