<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <Slides v-if="hash" />
      <Login v-else />
    </ion-content>
  </ion-page>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
import jwt from 'jsonwebtoken'
import moment from 'moment'

import graph from '@/modules/graph'
import stats from '@/modules/stats'

import { Autoplay, Keyboard, Pagination, Scrollbar, Zoom } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue'
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonRow,
  IonCol,
  IonIcon,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonSpinner,
  IonProgressBar,
} from '@ionic/vue'
import { arrowForwardOutline } from 'ionicons/icons'
import {
  computed,
  defineComponent,
  toRefs,
  reactive,
  ref,
  onMounted,
} from 'vue'

import Slides from '@/components/Slides'
import Login from '@/components/Login'

export default defineComponent({
  components: {
    Swiper,
    SwiperSlide,

    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonRow,
    IonCol,
    IonIcon,
    IonItem,
    IonLabel,
    IonInput,
    IonButton,
    IonSpinner,
    IonProgressBar,

    Slides,
    Login,
  },
  setup() {
    const { hash } = stats()
    const { getDate, toNumber, minMax, displayHandicap } = graph()
    // const loggedIn = ref(false)
    // const slides = ref()
    // const stats = ref({
    //   roundsYearly: {},
    //   roundsMonthly: {},
    //   scoringStats: {},
    //   newHandicaps: [],
    // })
    // const currentTime = ref(moment())
    // const currentSlide = ref(0)
    // const error = ref(null)
    // const loading = ref(false)
    // const generating = ref(false)
    // const memberStats = ref({
    //   members: [],
    // })
    // const userData = ref({})
    // const memberData = ref([])
    // // const userData = ref(require('../../public/assets/d.json'))
    // // const memberData = ref(require('../../public/assets/e.json'))
    // const form = reactive({
    //   email: '',
    //   golfLinkNo: '',
    // })
    //
    // onMounted(() => {
    //   // setInterval(() => {
    //   //   currentTime.value = moment()
    //   // }, 50)
    //   generate()
    //   generateMembers()
    // })
    //
    // const login = async () => {
    //   try {
    //     error.value = null
    //     loading.value = true
    //     userData.value = await axios({
    //       method: 'POST',
    //       data: form,
    //       url: `${process.env.VUE_APP_API_URL}/api/stats`,
    //       headers: {
    //         'x-api-key': await sign(),
    //       },
    //     })
    //       .then((response) => {
    //         return response.data
    //       })
    //       .catch((error) => {
    //         return { error: error.response.data }
    //       })
    //
    //     loading.value = false
    //
    //     if (userData.value.error) {
    //       error.value = userData.value.error
    //     } else {
    //       generating.value = true
    //       if (userData.value.members) {
    //         memberData.value = await axios({
    //           method: 'POST',
    //           url: `${process.env.VUE_APP_API_URL}/api/stats/members`,
    //           data: {
    //             handicaps: _.map(userData.value.members, 'golfLinkNo'),
    //           },
    //           headers: {
    //             'x-api-key': await sign(),
    //           },
    //         })
    //           .then((response) => {
    //             return response.data
    //           })
    //           .catch((error) => {
    //             return error.response.data
    //           })
    //       }
    //       generate()
    //       generateMembers()
    //       generating.value = false
    //       loggedIn.value = true
    //     }
    //   } catch (err) {
    //     console.log(err.message)
    //     generating.value = false
    //     loading.value = false
    //     error.value = 'Page error'
    //   }
    // }
    //
    // const generate = () => {
    //   let rounds = userData.value.rounds
    //   let courses = {}
    //   let handicap = {}
    //   let lastRound = null
    //
    //   let newHandicaps = []
    //
    //   let roundsYearly = {}
    //   let roundsMonthly = {}
    //
    //   let adjustedScores = []
    //   let stablefordScores = []
    //   let toParScores = []
    //
    //   let scoringStats = {}
    //
    //   if (rounds) {
    //     _.reverse(rounds).map((round) => {
    //       let year = moment(round.dateHeld).format('YYYY')
    //
    //       // ROUNDS
    //       if (!roundsYearly[year]) roundsYearly[year] = 0
    //       roundsYearly[year]++
    //
    //       if (
    //         moment(round.dateHeld) >= moment('2021-01-01 00:00') &&
    //         moment(round.dateHeld) <= moment('2021-12-31 23:59')
    //       ) {
    //         if (!isNaN(round.newHandicap))
    //           handicap.exactHandicap = round.newHandicap
    //         // ROUNDS MONTH
    //         let month = moment(round.dateHeld).format('M')
    //         if (!roundsMonthly[month]) roundsMonthly[month] = 0
    //         roundsMonthly[month]++
    //

    //
    //         if (!isNaN(round.newHandicap)) {
    //           newHandicaps.push({
    //             Date: getDate(round.dateHeld, null),
    //             Handicap: round.newHandicap,
    //           })
    //         }
    //
    //       } else if (!isNaN(round.newHandicap)) lastRound = round
    //     })
    //
    //     if (newHandicaps.length && lastRound) {
    //       newHandicaps.unshift({
    //         Date: getDate('2021-01-01 00:00', null),
    //         Handicap: lastRound.newHandicap,
    //       })
    //
    //       newHandicaps.push({
    //         Date: getDate('2021-12-31 23:59', null),
    //         Handicap: newHandicaps[newHandicaps.length - 1].Handicap,
    //       })
    //
    //       handicap.low = minMax(newHandicaps, 'Handicap')[0]
    //       handicap.high = minMax(newHandicaps, 'Handicap')[1]
    //     }
    //
    //     scoringStats = {
    //       stableford: {
    //         scores: stablefordScores,
    //         low:
    //           stablefordScores.length > 0
    //             ? minMax(stablefordScores, 'Value')[0]
    //             : null,
    //         avg:
    //           stablefordScores.length > 0
    //             ? _.sum(stablefordScores.map((d) => d.Value)) /
    //               stablefordScores.length
    //             : 0,
    //         high:
    //           stablefordScores.length > 0
    //             ? minMax(stablefordScores, 'Value')[1]
    //             : null,
    //       },
    //       adjusted: {
    //         scores: adjustedScores,
    //         low:
    //           adjustedScores.length > 0
    //             ? minMax(adjustedScores, 'Value')[0]
    //             : null,
    //         avg:
    //           adjustedScores.length > 0
    //             ? _.sum(adjustedScores.map((d) => d.Value)) /
    //               adjustedScores.length
    //             : 0,
    //         high:
    //           adjustedScores.length > 0
    //             ? minMax(adjustedScores, 'Value')[1]
    //             : null,
    //       },
    //       toPar: {
    //         scores: toParScores,
    //         low:
    //           toParScores.length > 0 ? minMax(toParScores, 'Value')[0] : null,
    //         avg:
    //           toParScores.length > 0
    //             ? _.sum(toParScores.map((d) => d.Value)) / toParScores.length
    //             : 0,
    //         high:
    //           toParScores.length > 0 ? minMax(toParScores, 'Value')[1] : null,
    //       },
    //     }
    //   }
    //
    //   // COURSES
    //   let coursesSorted = Object.entries(courses).sort((a, b) => b[1] - a[1])
    //
    //   stats.value = {
    //     name: userData.value.name,
    //     roundsYearly,
    //     roundsMonthly,
    //     scoringStats,
    //     newHandicaps,
    //     handicap,
    //     coursesPlayed: coursesSorted.length,
    //     favouriteCourse: coursesSorted.length ? coursesSorted[0][0] : null,
    //     exactHandicap: userData.value.details
    //       ? userData.value.details.exactHandicap
    //       : null,
    //   }
    // }
    //
    // const generateMembers = () => {
    //   let members = []
    //   memberData.value.map((member) => {
    //     member.nickname = _.find(
    //       userData.value.members,
    //       (o) => o.golfLinkNo == member.details.golfLinkNo
    //     ).nickname
    //     let rounds = member.rounds
    //
    //     if (rounds.length) {
    //       let played = 0
    //       let lastRound = null
    //       let handicaps = []
    //       let adjustedScores = []
    //       let stablefordScores = []
    //       let toParScores = []
    //       _.reverse(rounds).map((round) => {
    //         let year = moment(round.dateHeld).format('YYYY')
    //         round.adjustedGross = parseInt(round.adjustedGross)
    //         round.competitionScore = parseInt(round.competitionScore)
    //         round.playedTo = toNumber(round.playedTo)
    //         round.newHandicap = toNumber(round.newHandicap)
    //
    //         if (round.isNineHoleScore == false) {
    //           if (year == 2021) {
    //             played++
    //
    //             if (!isNaN(round.newHandicap)) {
    //               handicaps.push({
    //                 Date: getDate(round.dateHeld, null),
    //                 Handicap: round.newHandicap,
    //               })
    //             }
    //
    //             if (!isNaN(round.adjustedGross)) {
    //               adjustedScores.push({
    //                 Course: round.location,
    //                 Date: getDate(round.dateHeld, null),
    //                 Value: round.adjustedGross,
    //               })
    //             }
    //
    //             if (
    //               !isNaN(round.competitionScore) &&
    //               round.competitionType == 'Stableford'
    //             ) {
    //               stablefordScores.push({
    //                 Course: round.location,
    //                 Date: getDate(round.dateHeld, null),
    //                 Value: round.competitionScore,
    //               })
    //             }
    //
    //             if (!isNaN(round.playedTo)) {
    //               toParScores.push({
    //                 Course: round.location,
    //                 Date: getDate(round.dateHeld, null),
    //                 Value: round.playedTo,
    //               })
    //             }
    //           } else if (!isNaN(round.newHandicap) && year <= 2020)
    //             lastRound = round
    //         }
    //       })
    //
    //       if (handicaps.length && lastRound) {
    //         handicaps.unshift({
    //           Date: getDate('2021-01-01 00:00', null),
    //           Handicap: lastRound.newHandicap,
    //         })
    //
    //         handicaps.push({
    //           Date: getDate('2021-12-31 23:59', null),
    //           Handicap: handicaps[handicaps.length - 1].Handicap,
    //         })
    //
    //         let change = Math.abs(
    //           handicaps[handicaps.length - 1].Handicap - handicaps[0].Handicap
    //         )
    //
    //         let scoringStats = {
    //           stableford: {
    //             scores: stablefordScores,
    //             low:
    //               stablefordScores.length > 0
    //                 ? minMax(stablefordScores, 'Value')[0]
    //                 : null,
    //             avg:
    //               stablefordScores.length > 0
    //                 ? _.sum(stablefordScores.map((d) => d.Value)) /
    //                   stablefordScores.length
    //                 : null,
    //             high:
    //               stablefordScores.length > 0
    //                 ? minMax(stablefordScores, 'Value')[1]
    //                 : null,
    //           },
    //           adjusted: {
    //             scores: adjustedScores,
    //             low:
    //               adjustedScores.length > 0
    //                 ? minMax(adjustedScores, 'Value')[0]
    //                 : null,
    //             avg:
    //               adjustedScores.length > 0
    //                 ? _.sum(adjustedScores.map((d) => d.Value)) /
    //                   adjustedScores.length
    //                 : null,
    //             high:
    //               adjustedScores.length > 0
    //                 ? minMax(adjustedScores, 'Value')[1]
    //                 : null,
    //           },
    //           toPar: {
    //             scores: toParScores,
    //             low:
    //               toParScores.length > 0
    //                 ? minMax(toParScores, 'Value')[0]
    //                 : null,
    //             avg:
    //               toParScores.length > 0
    //                 ? _.sum(toParScores.map((d) => d.Value)) /
    //                   toParScores.length
    //                 : null,
    //             high:
    //               toParScores.length > 0
    //                 ? minMax(toParScores, 'Value')[1]
    //                 : null,
    //           },
    //         }
    //
    //         members.push({
    //           scoringStats,
    //           change,
    //           nickname: member.nickname,
    //           handicap: toNumber(member.details.exactHandicap),
    //           rounds,
    //           handicaps,
    //           played,
    //           adjustedScores,
    //           stablefordScores,
    //           toParScores,
    //         })
    //       }
    //     }
    //   })
    //
    //   memberStats.value = {
    //     members,
    //     handicapAverage: (
    //       _.sumBy(members, 'handicap') / members.length
    //     ).toFixed(1),
    //   }
    // }
    //
    //
    //
    // const endSlideshow = () => {
    //   slides.value.autoplay.stop()
    // }
    //
    // const slidePause = ref(null)
    // const slideStart = ref(moment())
    //
    // const slideChange = () => {
    //   currentSlide.value = slides.value.activeIndex
    //   slideStart.value = null
    //   if (delay.value) clearTimeout(delay.value)
    // }
    //
    // const slideChanged = () => {
    //   slideStart.value = moment()
    // }
    //
    // const lastTouch = ref(null)
    //
    // const slideTouch = (s, event) => {
    //   if (currentSlide.value > 0) {
    //     if (event && event.touches && event.touches.length)
    //       lastTouch.value = event.touches.item(0).pageX
    //     else lastTouch.value = event.pageX
    //     slidePause.value = moment()
    //     slides.value.autoplay.stop()
    //   }
    // }
    //
    // const delay = ref(null)
    // const slideTouchEnd = (s, event) => {
    //   let tapTime = moment().diff(slidePause.value, 'milliseconds')
    //
    //   if (currentSlide.value > 0) {
    //     if (tapTime <= 100) {
    //       if (lastTouch.value && lastTouch.value < 100) slides.value.slidePrev()
    //       else slides.value.slideNext()
    //       slidePause.value = null
    //       slides.value.autoplay.start()
    //     } else {
    //       let previous = moment(slidePause.value).diff(
    //         slideStart.value,
    //         'milliseconds'
    //       )
    //       slideStart.value = moment().subtract(previous, 'milliseconds')
    //       slidePause.value = null
    //
    //       delay.value = setTimeout(() => {
    //         slides.value.slideNext()
    //         slides.value.autoplay.start()
    //       }, 5000 - previous)
    //     }
    //   }
    // }

    return {
      // // FUNCTIONS
      // login,
      // setSwiperInstance,
      // endSlideshow,
      // slideChange,
      // slideChanged,
      // slideTouch,
      // slideTouchEnd,
      // //COMPUTED
      // timeDiff: computed(() => {
      //   if (!slideStart.value) return 0
      //   let diff = moment(currentTime.value).diff(
      //     slideStart.value,
      //     'milliseconds'
      //   )
      //   if (slidePause.value)
      //     diff = moment(slidePause.value).diff(slideStart.value, 'milliseconds')
      //   if (diff > 5100) return 100
      //   return (diff / 5000) * 100
      // }),
      // // VARS
      // ...toRefs(form),
      // slideStart,
      // currentSlide,
      // error,
      // loggedIn,
      // stats,
      // memberStats,
      // loading,
      // generating,
      // slides,
      // // ICONS
      // arrowForwardOutline,
      // modules: [Autoplay, Pagination, Scrollbar],

      // VARS
      hash,
    }
  },
})
</script>

<style lang="scss" scoped>
.story-swiper {
  top: 3px !important;
  position: absolute;
  z-index: 9999;
  width: 99%;
  height: 5px;
  display: block;

  .slide {
    background: rgba(0, 0, 0, 0.25);
    height: 5px;
    display: inline-block;
    margin-left: 1%;
    border-radius: 5px;
    z-index: 9999;
    overflow: hidden;
    float: left;

    .timer.active {
      background: rgba(255, 255, 255, 1);
      display: block;
      height: 5px;
      border-radius: 5px;
      z-index: 9999;
    }
  }
}
</style>

<style lang="scss">
.login {
  width: 100%;
  height: 100%;
  .bg {
    background: url('/assets/bg.jpg') no-repeat 50% 50%;
    background-size: cover;
    width: 100%;
    height: 100%;
    .overlay {
      background: rgba(0, 0, 0, 0.65);
      width: 100%;
      height: 100%;
    }
  }

  form {
    ion-col {
      margin-bottom: 10px;
    }
  }

  .error {
    display: block;
    color: var(--color-red);
    font-size: 1.15rem;
    margin-bottom: 1rem;
  }
}
</style>
