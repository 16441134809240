<template>
  <div class="slide-container full-height bg-blue">
    <div class="slide-content">
      <ion-row class="row-padding" style="height: 100%">
        <ion-col class="ion-text-left" size="12">
          <span class="stat-heading secondary"
            >Lets not forget about your friends</span
          >
          <p>
            Clubhouse members follow an average of
            <span class="secondary sub-stat">{{ (8.35).toFixed(1) }}</span>
            friends and have created an average of
            <span class="secondary sub-stat">{{ (1.36).toFixed(1) }}</span>
            societies.
          </p>
        </ion-col>
      </ion-row>
    </div>
  </div>
</template>

<script>
import { IonRow, IonCol } from '@ionic/vue'
import stats from '@/modules/stats'
export default {
  components: {
    IonRow,
    IonCol,
  },
  props: {
    slider: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const { user } = stats()
    // 2229980
    const start = () => {
      props.slider.slideNext()
      // props.slider.autoplay.start()
    }

    return {
      start,
      user,
    }
  },
}
</script>

<style lang="scss" scoped>
.stat-heading {
  font-size: 2.5rem !important;
}
</style>
