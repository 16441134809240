<template>
  <div class="slide-container full-height bg-pale">
    <div class="slide-content">
      <ion-row class="row-padding" style="height: 40%">
        <ion-col class="ion-text-left" size="12">
          <span class="stat-heading secondary">{{
            data.roundsPlayed[data.currentYear]
          }}</span>
          <p>
            You played
            <span class="secondary sub-stat">{{
              data.roundsPlayed[data.currentYear]
            }}</span>
            rounds in {{ data.currentYear }}<span v-html="diff()"></span>, and
            your most visited course was
            <span class="secondary sub-stat">{{ data.favouriteCourse }}</span
            >.
          </p>
        </ion-col>
      </ion-row>
      <ion-row style="height: 60%">
        <ion-col size="12">
          <div class="rounds-monthly" ref="rounds-monthly"></div>
        </ion-col>
      </ion-row>
    </div>
  </div>
</template>

<script>
import * as d3 from 'd3'
import _ from 'lodash'
import moment from 'moment'
import { onMounted, ref, getCurrentInstance } from 'vue'
import { IonRow, IonCol } from '@ionic/vue'
import stats from '@/modules/stats'
export default {
  components: {
    IonRow,
    IonCol,
  },
  setup() {
    const { data } = stats()
    const root = ref(getCurrentInstance())
    onMounted(() => {
      setTimeout(() => {
        plot()
      }, 1000)
    })

    const plot = async () => {
      await d3.selectAll(`.rounds-monthly svg`).remove()
      let width = root.value.refs['rounds-monthly']
        ? root.value.refs['rounds-monthly'].clientWidth
        : 375
      let height = root.value.refs['rounds-monthly']
        ? root.value.refs['rounds-monthly'].clientHeight
        : 400

      let points = []
      for (let i = 1; i <= 12; i++) {
        let rounds = data.value.roundsMonthly[i]
        points.push({
          Rounds: rounds || 0,
          Month: moment(`2021-${i < 10 ? `0${i}` : i}-01`).format('MMM'),
        })
      }

      const svg = d3
        .select(`.rounds-monthly`)
        .append('svg')
        .attr('width', width)
        .attr('height', height)

      let max = _.maxBy(points, 'Rounds').Rounds
      let min = [0, !max ? 1 : max]

      let x = d3
          .scaleLinear()
          .domain(min)
          .range([55, width - 55]),
        y = d3
          .scaleBand()
          .domain(points.map((d, index) => index))
          .range([50, height - 50])

      svg
        .selectAll('lines')
        .data(points)
        .enter()
        .append('rect')
        .attr('x', x(0))
        .attr('y', (d, index) => y(index))
        .attr('width', (d) => x(d.Rounds) - 55)
        .attr('height', 2)

      svg
        .selectAll('months')
        .data(points)
        .enter()
        .append('text')
        .text((d) => d.Month)
        .attr('x', 45)
        .attr('y', (d, index) => y(index) + 5)
        .attr('text-anchor', 'end')
        .attr('class', 'month')

      svg
        .selectAll('dots')
        .data(points)
        .enter()
        .append('circle')
        .attr('r', 5)
        .attr('cx', (d, index) => x(d.Rounds))
        .attr('cy', (d, index) => y(index) + 0.5)

      svg
        .selectAll('rounds')
        .data(points)
        .enter()
        .append('text')
        .text((d) => d.Rounds)
        .attr('x', (d, index) => x(d.Rounds) + 10)
        .attr('y', (d, index) => y(index) + 6)
        .attr('text-anchor', 'start')
        .attr('class', (d) => `month${d.Rounds == 0 ? ' no-rounds' : ''}`)
    }

    const diff = () => {
      if (data.value.roundsPlayed[data.value.currentYear - 1] == 0) return ''
      else {
        let change =
          data.value.roundsPlayed[data.value.currentYear] -
          data.value.roundsPlayed[data.value.currentYear - 1]

        return ` that's <span class="secondary1 sub-stat">${Math.abs(
          change
        )}</span> round${
          Math.abs(change) > 1 ? 's' : ''
        } <span class="secondary1 sub-stat">${
          change > 0 ? 'more' : 'less'
        }</span> than ${data.value.currentYear - 1}`
      }
    }

    return {
      diff,
      data,
    }
  },
}
</script>
<style lang="scss" scoped>
.secondary {
  color: var(--color-blue);
}
</style>

<style lang="scss">
.secondary1 {
  color: var(--color-blue);
}
.rounds-monthly {
  width: 100%;
  height: 100%;
  overflow: hidden;

  text {
    &.month {
      fill: var(--color-blue);
      font-size: 0.9rem;
    }
  }

  rect {
    fill: var(--color-blue);
  }

  circle {
    fill: var(--color-blue);
  }
}
</style>
