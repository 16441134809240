<template>
  <div class="slide-container full-height bg-coral">
    <div class="slide-content">
      <ion-row class="row-padding" style="height: 40%">
        <ion-col class="ion-text-left" size="12">
          <span class="stat-heading secondary">{{
            displayHandicap(data.exactHandicap)
          }}</span>
          <p>
            Your current handicap is
            <span class="secondary sub-stat">{{
              displayHandicap(data.exactHandicap)
            }}</span
            >. You reached a low of
            <span class="secondary sub-stat">{{
              displayHandicap(data.handicapHistory.low)
            }}</span>
            and a high of
            <span class="secondary sub-stat">{{
              displayHandicap(data.handicapHistory.high)
            }}</span
            >.
          </p>
        </ion-col>
      </ion-row>
      <ion-row style="height: 60%">
        <ion-col size="12">
          <div class="handicap-history" ref="handicap-history"></div>
        </ion-col>
      </ion-row>
    </div>
  </div>
</template>

<script>
import * as d3 from 'd3'
import _ from 'lodash'
import moment from 'moment'
import { onMounted, ref, getCurrentInstance } from 'vue'
import { IonRow, IonCol } from '@ionic/vue'
import stats from '@/modules/stats'
export default {
  components: {
    IonRow,
    IonCol,
  },
  setup() {
    const { data, displayHandicap, getDate, handicapConvert } = stats()
    const root = ref(getCurrentInstance())
    onMounted(() => {
      setTimeout(() => {
        plot()
      }, 1000)
    })

    const plot = async () => {
      let scores = data.value.handicapHistory.scores
      let style = 'handicap-history'
      let width = root.value.refs[style]
        ? root.value.refs[style].clientWidth
        : 300
      let height = root.value.refs[style]
        ? root.value.refs[style].clientHeight
        : 300
      await d3.selectAll(`.${style} svg`).remove()

      const svg = d3
        .select(`.${style}`)
        .append('svg')
        .attr('width', width)
        .attr('height', height)

      let min = [
        data.value.handicapHistory.low - 1,
        data.value.handicapHistory.high + 1,
      ]

      let x = d3
          .scaleTime()
          .domain(
            d3.extent(
              [
                getDate('2021-01-01 00:00', null),
                getDate('2021-12-31 23:59', null),
              ],
              (d) => d
            )
          )
          .range([30, width - 30]),
        y = d3
          .scaleLinear()
          .domain(min)
          .range([height - 10, 20])

      svg
        .append('g')
        .attr('class', `axis axis--x`)
        .attr('transform', `translate(0,${height - 30})`)
        .call(
          d3
            .axisBottom(x)
            .ticks(d3.timeMonth.every(1))
            .tickFormat(d3.timeFormat('%b'))
        )
        .selectAll('text')
        .attr('y', -((width - 60) / 24))
        .attr('x', 0)
        .attr('dy', '.35em')
        .attr('transform', 'rotate(90)')
        .style('text-anchor', 'start')

      for (let i = 1; i <= 13; i++) {
        let m = i
        let d = '01'
        let year = 2021
        if (i == 13) {
          m = 12
          d = '31'
        }

        let date = getDate(`${year}-${m < 10 ? `0${m}` : m}-${d}`, null)
        svg
          .append('line')
          .attr('x1', x(date))
          .attr('x2', x(date))
          .attr('y1', height - 50)
          .attr('y2', 60)
          .attr('class', 'x-line')
          .style('width', 40)

        let bisect = d3
          .bisector(function (d) {
            return d.Date
          })
          .left(scores, date)

        let item = scores[bisect]
        svg
          .append('text')
          .text(displayHandicap(item.Value))
          .attr('transform', `translate(${x(date) - 5}, 50) rotate(90)`)
          .attr('x', 0)
          .attr('y', 0)
          .attr('text-anchor', 'end')
          .attr('class', 'monthly-handicap')

        svg
          .append('circle')
          .attr('r', 5)
          .attr('cx', x(date))
          .attr('cy', y(item.Value))
      }

      svg
        .append('path')
        .datum(scores)
        .attr('class', 'handicap-line')
        .attr(
          'd',
          d3
            .line()
            .x((d) => x(d.Date))
            .y((d) => y(d.Value))
            .defined((d) => d.Value)
            .curve(d3.curveMonotoneX)
        )
    }

    return {
      displayHandicap,
      data,
    }
  },
}
</script>
<style lang="scss" scoped>
.secondary {
  color: var(--color-blue);
}
</style>
<style lang="scss">
.handicap-history {
  height: 100%;
  width: 100%;

  .handicap-line {
    stroke-width: 1.5;
    fill: none;
    stroke: var(--color-blue);
  }

  .x-line {
    stroke: rgba(0, 145, 110, 0.2);
  }

  .monthly-handicap {
    font-size: 0.75rem;
    fill: var(--color-green);
  }

  circle {
    fill: var(--color-blue);
  }

  .axis--x {
    path {
      display: none;
    }

    line {
      display: none;
    }

    text {
      font-size: 0.75rem;
      fill: var(--color-green);
    }
  }
}
</style>
