<template>
  <div
    class="slide-container full-height bg-blue share-slider-1"
    ref="share-slider-1"
  >
    <div class="slide-content">
      <ion-row class="row-padding" style="height: 25%">
        <ion-col class="ion-text-left" size="12"> &nbsp; </ion-col>
      </ion-row>
      <ion-row class="summary-container" style="height: 50%">
        <ion-col class="ion-text-left" size="12">
          <div class="summary-1 border-bottom" style="height: 20%">
            <div
              class="padding border-right"
              style="background: var(--color-yellow); width: 34%"
            >
              <span class="attr">Rounds</span>
              <span class="val">{{ data.roundsPlayed[data.currentYear] }}</span>
            </div>
            <div
              class="padding"
              style="background: var(--color-coral); width: 66%"
            >
              <span class="attr">Favourite Course</span>
              <span class="val small">{{ data.favouriteCourse }}</span>
            </div>
          </div>
          <div class="summary-1" style="height: 80%">
            <div class="border-right" style="width: 66%">
              <div class="border-bottom" style="height: 50%; width: 100%">
                <div style="background: var(--color-gains); width: 100%">
                  <div
                    class="summary-rounds-monthly"
                    ref="summary-rounds-monthly"
                  ></div>
                </div>
              </div>
              <div style="height: 50%; width: 100%">
                <div style="background: var(--color-coral); width: 100%">
                  <div
                    class="summary-handicap-history"
                    ref="summary-handicap-history"
                  ></div>
                </div>
              </div>
            </div>
            <div style="background: var(--color-pale); width: 34%">
              <div
                class="summary-scoring-stats"
                ref="summary-scoring-stats"
              ></div>
            </div>
          </div>
        </ion-col>
      </ion-row>
      <ion-row class="row-padding" style="height: 25%">
        <ion-col class="ion-text-left" size="12"> &nbsp; </ion-col>
      </ion-row>
    </div>
  </div>
</template>

<script>
import * as d3 from 'd3'
import _ from 'lodash'
import moment from 'moment'
import { onMounted, ref, getCurrentInstance } from 'vue'
import { IonRow, IonCol } from '@ionic/vue'
import stats from '@/modules/stats'
export default {
  components: {
    IonRow,
    IonCol,
  },
  setup() {
    const {
      data,
      membersData,
      members,
      displayHandicap,
      getDate,
      handicapConvert,
      shorten,
    } = stats()
    const root = ref(getCurrentInstance())
    onMounted(() => {
      setTimeout(() => {
        bg()
        roundsMonthly()
        handicapGraph()
        scoringStats()
      }, 1000)
    })

    const bg = async () => {
      let style = 'share-slider-1'
      await d3.selectAll(`.${style} svg`).remove()
      let width = root.value.refs[style]
        ? root.value.refs[style].clientWidth
        : 375
      let height = root.value.refs[style]
        ? root.value.refs[style].clientHeight
        : 400

      const svg = d3
        .select(`.${style}`)
        .append('svg')
        .attr('width', width)
        .attr('height', height)

      for (let x = 0; x < 40; x++) {
        for (let y = 0; y < 40; y++) {
          svg
            .append('svg:image')
            .attr('width', 50)
            .attr('height', 50)
            .attr(
              'transform',
              `translate(${x * 75}, ${
                y * 100 + (x % 2 == 0 ? 50 : 0)
              }) rotate(-45)`
            )
            .attr('xlink:href', '/assets/icon.png')
            .attr('opacity', 0.1)
        }
      }
    }

    const roundsMonthly = async () => {
      let style = 'summary-rounds-monthly'
      await d3.selectAll(`.${style} svg`).remove()
      let width = root.value.refs[style]
        ? root.value.refs[style].clientWidth
        : 375
      let height = root.value.refs[style]
        ? root.value.refs[style].clientHeight
        : 400

      let points = []
      for (let i = 1; i <= 12; i++) {
        let rounds = data.value.roundsMonthly[i]
        points.push({
          Rounds: rounds || 0,
          Month: moment(`2021-${i < 10 ? `0${i}` : i}-01`).format('MMM'),
        })
      }

      const svg = d3
        .select(`.${style}`)
        .append('svg')
        .attr('width', width)
        .attr('height', height)

      let max = _.maxBy(points, 'Rounds').Rounds
      let min = [0, !max ? 1 : max]

      let x = d3
          .scaleLinear()
          .domain(min)
          .range([35, width - 35]),
        y = d3
          .scaleBand()
          .domain(points.map((d, index) => index))
          .range([15, height - 5])

      svg
        .selectAll('lines')
        .data(points)
        .enter()
        .append('rect')
        .attr('x', x(0))
        .attr('y', (d, index) => y(index))
        .attr('width', (d) => x(d.Rounds) - 35)
        .attr('height', 1)

      svg
        .selectAll('months')
        .data(points)
        .enter()
        .append('text')
        .text((d) => d.Month)
        .attr('x', 30)
        .attr('y', (d, index) => y(index) + 2.5)
        .attr('text-anchor', 'end')
        .attr('class', 'month')

      svg
        .selectAll('dots')
        .data(points)
        .enter()
        .append('circle')
        .attr('r', 2.5)
        .attr('cx', (d, index) => x(d.Rounds))
        .attr('cy', (d, index) => y(index) + 0.5)

      svg
        .selectAll('rounds')
        .data(points)
        .enter()
        .append('text')
        .text((d) => d.Rounds)
        .attr('x', (d, index) => x(d.Rounds) + 10)
        .attr('y', (d, index) => y(index) + 3)
        .attr('text-anchor', 'start')
        .attr('class', (d) => `month${d.Rounds == 0 ? ' no-rounds' : ''}`)
    }

    const handicapGraph = async () => {
      let scores = data.value.handicapHistory.scores
      let style = 'summary-handicap-history'
      let width = root.value.refs[style]
        ? root.value.refs[style].clientWidth
        : 300
      let height = root.value.refs[style]
        ? root.value.refs[style].clientHeight
        : 300
      await d3.selectAll(`.${style} svg`).remove()

      const svg = d3
        .select(`.${style}`)
        .append('svg')
        .attr('width', width)
        .attr('height', height)

      let min = [
        data.value.handicapHistory.low - 1,
        data.value.handicapHistory.high + 1,
      ]

      let x = d3
          .scaleTime()
          .domain(
            d3.extent(
              [
                getDate('2021-01-01 00:00', null),
                getDate('2021-12-31 23:59', null),
              ],
              (d) => d
            )
          )
          .range([15, width - 30]),
        y = d3
          .scaleLinear()
          .domain(min)
          .range([height - 10, 10])

      svg
        .append('g')
        .attr('class', `axis axis--x`)
        .attr('transform', `translate(0,${height - 30})`)
        .call(
          d3
            .axisBottom(x)
            .ticks(d3.timeMonth.every(1))
            .tickFormat(d3.timeFormat('%b'))
        )
        .selectAll('text')
        .attr('y', -((width - 60) / 24))
        .attr('x', 10)
        .attr('dy', '.35em')
        .attr('transform', 'rotate(90)')
        .style('text-anchor', 'start')

      for (let i = 1; i <= 13; i++) {
        let m = i
        let d = '01'
        let year = 2021
        if (i == 13) {
          m = 12
          d = '31'
        }

        let date = getDate(`${year}-${m < 10 ? `0${m}` : m}-${d}`, null)
        svg
          .append('line')
          .attr('x1', x(date))
          .attr('x2', x(date))
          .attr('y1', height - 30)
          .attr('y2', 20)
          .attr('class', 'x-line')
          .style('width', 40)
      }

      svg
        .append('path')
        .datum(scores)
        .attr('class', 'handicap-line')
        .attr(
          'd',
          d3
            .line()
            .x((d) => x(d.Date))
            .y((d) => y(d.Value))
            .defined((d) => d.Value)
            .curve(d3.curveMonotoneX)
        )

      svg
        .append('text')
        .text(data.value.handicapHistory.low.toFixed(1))
        .attr('x', width - 27.5)
        .attr('y', y(data.value.handicapHistory.low))
        .attr('text-anchor', 'start')
        .attr('class', 'monthly-handicap')

      svg
        .append('text')
        .text(data.value.handicapHistory.high.toFixed(1))
        .attr('x', width - 27.5)
        .attr('y', y(data.value.handicapHistory.high))
        .attr('text-anchor', 'start')
        .attr('class', 'monthly-handicap')
    }

    const scoringStats = async () => {
      let style = 'summary-scoring-stats'
      let width = root.value.refs[style]
        ? root.value.refs[style].clientWidth
        : 300
      let height = root.value.refs[style]
        ? root.value.refs[style].clientHeight
        : 300
      await d3.selectAll(`.${style} svg`).remove()

      const svg = d3
        .select(`.${style}`)
        .append('svg')
        .attr('width', width)
        .attr('height', height)

      let mapping = ['stableford', 'topar', 'adjusted']

      mapping.map((type, index) => {
        let best = 'low'
        if (type == 'stableford') best = 'high'

        svg
          .append('text')
          .text(type)
          .attr('x', width / 2)
          .attr('y', (index + 1) * (height / 3) - height / 3 + 20)
          .attr('text-anchor', 'middle')
          .attr('class', 'scoring-label')

        svg
          .append('text')
          .text('avg')
          .attr('x', width / 3.25)
          .attr('y', (index + 1) * (height / 3) - height / 3 / 4.5)
          .attr('text-anchor', 'middle')
          .attr('class', 'scoring-sub-label')

        svg
          .append('text')
          .text('best')
          .attr('x', 2 * (width / 2.75))
          .attr('y', (index + 1) * (height / 3) - height / 3 / 4.5)
          .attr('text-anchor', 'middle')
          .attr('class', 'scoring-sub-label')

        svg
          .append('text')
          .text(data.value.scoring[type].avg.toFixed(1))
          .attr('x', width / 3.25)
          .attr('y', (index + 1) * (height / 3) - height / 3 / 2.75)
          .attr('text-anchor', 'middle')
          .attr('class', 'scoring-value')

        svg
          .append('text')
          .text(data.value.scoring[type][best])
          .attr('x', 2 * (width / 2.75))
          .attr('y', (index + 1) * (height / 3) - height / 3 / 2.75)
          .attr('text-anchor', 'middle')
          .attr('class', 'scoring-value')
      })
    }

    return {
      displayHandicap,
      data,
      members,
      membersData,
    }
  },
}
</script>

<style lang="scss" scoped>
.summary-container {
  overflow: hidden;
  ion-col {
    height: 100%;
  }

  .border-bottom {
    border-bottom: 2px solid var(--color-blue);
  }

  .border-right {
    border-right: 2px solid var(--color-blue);
  }

  .summary-1 {
    overflow: hidden;
    div {
      display: inline-block;
      height: 100%;
      float: left;
      overflow: hidden;

      &.padding {
        padding: 0.5rem 1rem;
      }
    }

    .attr {
      color: var(--color-blue);
      font-weight: 400;
      display: block;
      text-transform: uppercase;
      font-size: 0.7rem;
    }

    .val {
      color: var(--color-blue);
      font-weight: 600;
      display: block;
      font-size: 1.25rem;

      &.small {
        font-size: 1.25rem;
      }
    }
  }
}
</style>

<style lang="scss">
.summary-rounds-monthly {
  width: 100%;
  height: 100%;
  overflow: hidden;

  text {
    &.month {
      fill: var(--color-green);
      font-size: 0.5rem;
    }
  }

  rect {
    fill: var(--color-green);
  }

  circle {
    fill: var(--color-green);
  }
}
.summary-handicap-history {
  height: 100%;
  width: 100%;

  .handicap-line {
    stroke-width: 1.5;
    fill: none;
    stroke: var(--color-blue);
  }

  .x-line {
    stroke: rgba(0, 145, 110, 0.2);
  }

  .monthly-handicap {
    font-size: 0.85rem;
    fill: var(--color-green);
  }

  circle {
    fill: var(--color-blue);
  }

  .axis--x {
    path {
      display: none;
    }

    line {
      display: none;
    }

    text {
      font-size: 0.5rem;
      fill: var(--color-green);
    }
  }
}
.summary-scoring-stats {
  height: 100%;
  width: 100%;

  .scoring-label {
    fill: var(--color-blue);
    font-weight: 600;
    font-size: 0.8rem;
    text-transform: uppercase;
  }

  .scoring-sub-label {
    fill: var(--color-blue);
    font-weight: 400;
    font-size: 0.6rem;
    text-transform: uppercase;
  }

  .scoring-value {
    fill: var(--color-green);
    font-weight: 600;
    font-size: 1.25rem;
  }
}
</style>
