<template>
  <div style="height: 100%; width: 100%" ref="container">
    <scrollbar
      :slider="instance"
      :container="container"
      :slide="slide"
      :tap="tap"
    />
    <swiper
      class="full-height"
      @touchStart="touchStart"
      @touchEnd="touchEnd"
      @swiper="setSwiperInstance"
      @slideChangeTransitionStart="slideChange"
      @slideChangeTransitionEnd="slideChanged"
      :modules="modules"
      :autoplay="{ delay: 10000, stopOnLastSlide: true }"
      :allowTouchMove="false"
    >
      <swiper-slide class="full-height">
        <slide1 :slider="instance" />
      </swiper-slide>
      <swiper-slide class="full-height">
        <slide2 />
      </swiper-slide>
      <swiper-slide class="full-height">
        <slide3 />
      </swiper-slide>
      <swiper-slide class="full-height">
        <slide4 />
      </swiper-slide>
      <swiper-slide
        class="full-height"
        v-if="members && members.length && !shared"
      >
        <slide5 />
      </swiper-slide>
      <swiper-slide
        class="full-height"
        v-if="members && members.length && !shared"
      >
        <slide6 />
      </swiper-slide>
      <swiper-slide
        class="full-height"
        v-if="members && members.length && !shared"
      >
        <slide7 />
      </swiper-slide>
      <swiper-slide
        class="full-height"
        v-if="members && members.length && !shared"
      >
        <slide8 />
      </swiper-slide>
      <swiper-slide
        class="full-height"
        v-if="members && members.length && !shared"
      >
        <slide9 />
      </swiper-slide>
      <swiper-slide class="full-height">
        <slide10 />
      </swiper-slide>
      <swiper-slide
        class="full-height"
        v-if="members && members.length && !shared"
      >
        <slide11 />
      </swiper-slide>
      <swiper-slide class="full-height" v-if="!shared">
        <slide12 />
      </swiper-slide>
      <swiper-slide class="full-height" v-else>
        <slide13 />
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue'
import { defineComponent, getCurrentInstance, reactive, toRefs, ref } from 'vue'
import moment from 'moment'

import stats from '@/modules/stats'

import Scrollbar from '@/components/Scrollbar'
import Slide1 from '@/components/Slide1'
import Slide2 from '@/components/Slide2'
import Slide3 from '@/components/Slide3'
import Slide4 from '@/components/Slide4'
import Slide5 from '@/components/Slide5'
import Slide6 from '@/components/Slide6'
import Slide7 from '@/components/Slide7'
import Slide8 from '@/components/Slide8'
import Slide9 from '@/components/Slide9'
import Slide10 from '@/components/Slide10'
import Slide11 from '@/components/Slide11'
import Slide12 from '@/components/Slide12'
import Slide13 from '@/components/Slide13'

export default defineComponent({
  components: {
    Swiper,
    SwiperSlide,

    Scrollbar,
    Slide1,
    Slide2,
    Slide3,
    Slide4,
    Slide5,
    Slide6,
    Slide7,
    Slide8,
    Slide9,
    Slide10,
    Slide11,
    Slide12,
    Slide13,
  },
  setup() {
    const { shared, members } = stats()
    const delay = ref(null)
    const container = ref(getCurrentInstance())
    const slider = reactive({
      instance: null,
      slide: {
        started: null,
        change: false,
      },
      tap: {
        start: null,
        time: null,
        pos: null,
      },
    })

    const setSwiperInstance = (swiper) => {
      slider.instance = swiper
      slider.instance.autoplay.stop()
    }

    const touchStart = (swiper, event) => {
      if (!swiper.isBeginning) {
        if (event && event.touches && event.touches.length)
          slider.tap.pos = event.touches.item(0).pageX
        else slider.tap.pos = event.pageX
        slider.tap.start = moment()
        swiper.autoplay.stop()
      }
    }

    const touchEnd = (swiper, event) => {
      if (!swiper.isBeginning) {
        slider.tap.time = moment().diff(slider.tap.start, 'milliseconds')
        if (slider.tap.time < 200) {
          if (slider.tap.pos < container.value.clientWidth / 3)
            swiper.slidePrev()
          else if (!swiper.isEnd) swiper.slideNext()
          swiper.autoplay.start()
        } else {
          let previous = moment(slider.tap.start).diff(
            slider.slide.started,
            'milliseconds'
          )
          delay.value = setTimeout(() => {
            swiper.slideNext()
            swiper.autoplay.start()
          }, 10000 - previous)
          slider.slide.started = moment().subtract(previous, 'milliseconds')
        }
        slider.tap.start = null
      }
    }

    const slideChange = () => {
      if (delay.value) clearTimeout(delay.value)
      slider.slide.started = null
      slider.slide.change = true
    }

    const slideChanged = () => {
      slider.slide.started = moment()
      slider.slide.change = false
    }

    return {
      modules: [Autoplay],
      // FUNCTIONS
      setSwiperInstance,
      slideChange,
      slideChanged,
      touchStart,
      touchEnd,
      // VARS
      ...toRefs(slider),
      container,
      members,
      shared,
    }
  },
})
</script>
